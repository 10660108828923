import React from "react";
import { connect } from "react-redux";
import { Layout, Modal, message, Empty, Spin, Tabs, Button, Upload } from "antd";
import CustomIcon from "components/CustomIcon";
import userModel from "store/reducers/userModel";
import indexModel from "store/reducers/indexModel";
import * as indexService from "services/indexService";
import * as commonService from "services/commonService";
import PositionCard from "components/PositionCard/PositionCard";
// import SecondTabs from "components/SecondTabs/tabs";
import HomeList from "./HomeList";
import UploadResumeModal from '../resume/upload-resume';
import resumeModel from "store/reducers/resumeModel";
import "./center.scss"

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;

@connect(
  ({ index, user }) => {
    return { ...index, user: user.info || {} };
  },
  {
    ...userModel.actions,
    ...indexModel.actions,
    ...resumeModel.actions
  }
)
export default class Center extends React.Component {
  state = {
    active: "1",
    uploadResume: {},
    uploadResumeModal: false,
    profile_option: {
      basic: {
      },
      education: {},
      intent: {},
      job: {},
    },
  }

  componentDidMount() {
    console.log(666, this.props)
    commonService.getCommonData(["profile_option", "function_type_option"]).then(res => {
      res.profile_option.function_type_option = res.function_type_option;
      this.setState({
        profile_option: res.profile_option
      })
    });
  }

  componentWillUnmount() {

  }
  closeUploadMolda = () => {
    this.setState({
      uploadResume: {},
      uploadResumeModal: false,
    })
  }

  businessCardClick = (item, e) => {
    const { history } = this.props;
    history.push("/businessDetail/" + item.id)
  }
  
  onTab = (key) => {
    console.log('key', key)
    this.setState({ active: `${key}` }, () => {
      console.log(this.state)
    })
  }

  onChange = (info) => {
    if (!this.loadingHide) {
      this.loadingHide = null;
    }
    if (info.file.status === 'uploading') {
      if (!this.loadingHide) {
        this.loadingHide = message.loading("简历解析中...",0);
      }
    }
    if (info.file.status === 'done') {
      this.loadingHide && this.loadingHide();
      this.loadingHide = null;
      let res = info.file.response;
      if (res.code === 0) {
        this.setState({
          uploadResume: res.data,
          uploadResumeModal: true,
        })
      } else {
        message.error(res.msg);
      }
    } else if (info.file.status === 'error') {
      this.loadingHide && this.loadingHide();
      this.loadingHide = null;
      message.error(`${info.file.name}上传失败`);
    }
  }

  render() {
    const _this = this;
    const { history, user, editResume, delResume, updateResumeIsEdit } = this.props;
    const { active, uploadResume, uploadResumeModal, profile_option } = this.state;
    console.log(history,'history')
   
    const uploadProps = {
      name: 'attachment',
      action: '/cpc/v1/profile/analysis',
      showUploadList: false,
      onChange: this.onChange,
    };
    const resumeAction = { delResume, editResume, updateResumeIsEdit };

    return (
      <div >
        {/* <SecondTabs history={this.props.history} /> */}
        <div className="center-content" onClick={()=>message.destroy()}>
          <div className="center-display">
            <div className="center-wrapper">
              <div className="position-box">
                <Tabs onChange={this.onTab} animated={false} activeKey={active}>
                  <TabPane tab="最新发布" key="1" />
                  <TabPane tab="我投递的职位" key="2" />
                  <TabPane tab="我收藏的职位" key="3" />
                </Tabs>
                <HomeList type={active} history={this.props.history} />
              </div>
              <div className="base-info">
                <div className="info">
                  <div className="row">
                    {user.avatar&&<img src={user.avatar} />}
                    <div className="right-base" >
                      <p className="ellipsis" title={user.name}>{user.name}</p>
                      <p className="ellipsis" title={user.position}>{user.position}</p>
                      <p className="ellipsis" title={user.company_name} style={{ color: "#999999" }}>{user.company_name}</p>
                    </div>
                  </div>
                  <div className="update">
                    <Upload {...uploadProps}>
                      <Button type="primary" className="btn"><CustomIcon type="icon-cloud-upload" style={{ fontSize: "15px", marginRight: "4px" }} />上传简历附件</Button>
                    </Upload>
                  </div>
                  <div className="my">
                    <div className="left-side" onClick={this.onTab.bind(this, 2)}>
                      <div className="number">{user.jd_apply_num}</div>
                      <div >我的投递职位</div>
                    </div>
                    <span className="line" />
                    <div className="right-side" onClick={this.onTab.bind(this, 3)}>
                      <div className="number">{user.jd_collection_num}</div>
                      <div >我收藏的职位</div></div>
                  </div>
                </div>
                <div className="ad"><img src={require(`assets/imgs/position/ad.png`)} /></div>
              </div>
            </div>
          </div>
        </div>
        <UploadResumeModal visible={uploadResumeModal} onClose={this.closeUploadMolda} resume={uploadResume} resumeAction={resumeAction} options={profile_option}  history={this.props.history} />
      </div>
    );
  }
}
