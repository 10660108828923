import React from "react";
import { connect } from "react-redux";
import { Button, Progress, Switch, Upload, message, Anchor, Modal, Select } from "antd";
import CustomIcon from "components/CustomIcon";
import userModel from "store/reducers/userModel";
import resumeModel from "store/reducers/resumeModel";
import * as commonService from "services/commonService";
import SecondTabs from "components/SecondTabs/tabs";
import BaseInfo from './baseinfo';
import Intent from './intent';
import Work from './work';
import Project from './project';
import Education from './education';
import UploadResumeModal from './upload-resume';

import "./resume.scss"

const { Link } = Anchor;
const { Option } = Select;

@connect(
  ({ resumeModel, user }) => {
    return { ...resumeModel, user: user.info };
  },
  {
    ...userModel.actions,
    ...resumeModel.actions
  }
)
export default class Resume extends React.Component {
  // static async getInitialProps({ pathname, query = {}, req, res }) {
  //   console.log("dsadadsddddddd")
  //   return {};
  // }
  state = {
    profile_option: {
      basic: {
      },
      education: {},
      intent: {},
      job: {},
    },
    uploadResume: {},
    uploadResumeModal: false,
    isOpen: false,
    isOpenSel: false,
  }

  componentDidMount() {
    document.title = "我的简历-找工作上即派优职-专注人事财务职位";
    const {getResumeDetail, user, openLoginModal,location={},isOpenResumeSet} = this.props;
    console.log('location',location)
    if(location.query&&location.query.isLoad){
      //打开
      isOpenResumeSet();
    }
    getResumeDetail().then(res => {
      if (res.code === 0) {
        // this.setState({
        //   isOpen: res.data && res.data.is_open_resume ? res.data.is_open_resume : undefined
        // })
      }
    });
    commonService.getCommonData(["profile_option", "function_type_option"]).then(res => {
      res.profile_option.function_type_option = res.function_type_option;
      this.setState({
        profile_option: res.profile_option,
      })
    });
    // openLoginModal({
    //   visible: true,
    //   callBack: () => message.error("test")
    // })
    // getHotCooper({
    //   scene: 2,
    //   "per-page": 10,
    // });
    // indexService.getIndexImg({scene_id: 7}).then(res => {
    //   if (res.code === 0 && res.data) {
    //     this.setState({
    //       indexImgs: res.data
    //     })
    //   }
    // });
    document.title="我的简历-找工作上即派优职-专注人事财务职位";
  }
 
  componentDidUpdate(prevProps) {
    const { is } = require("immutable");
    if (!is(prevProps.user, this.props.user)){
      this.setState({
        isOpen: this.props.user.is_open_resume ? this.props.user.is_open_resume : undefined
      })
    }
  }

  componentWillUnmount() {
    const {updateResume} = this.props;
    updateResume({});
  }

  closeUploadMolda = () => {
    this.setState({
      uploadResume: {},
      uploadResumeModal: false,
    })
  }

  openChange = (value) => {
    const _this = this;
    const {modifyResumeSetting} = this.props;
    // if (checked) {
    //   Modal.confirm({
    //     title: '隐藏简历确认',
    //     content: '不允许任何人员或公司查看您的简历，个人信息完全保密，除非你主动投递职位。',
    //     okText: '确定',
    //     cancelText: "取消",
    //     onOk() {
    //       modifyResumeSetting({
    //         is_open_resume: checked ? 0 : 1
    //       }).then(res => {
    //         if (res.code === 0) {
    //           _this.setState({
    //             isOpen: checked
    //           })
    //         } else {
    //           message.error(res.errorMsg);
    //         }
    //       })
    //     },
    //     onCancel() {
    //     },
    //   })
    // } else {
    //   modifyResumeSetting({
    //     is_open_resume: checked ? 0 : 1
    //   }).then(res => {
    //     if (res.code === 0) {
    //       this.setState({
    //         isOpen: checked
    //       })
    //     } else {
    //       message.error(res.errorMsg);
    //     }
       
    //   })
    // }
    modifyResumeSetting({
      is_open_resume: value
    }).then(res => {
      if (res.code === 0) {
        this.setState({
          isOpen: value
        })
      } else {
        message.error(res.errorMsg);
      }
     
    })
  }

  onChange = (info) => {
    if (!this.loadingHide) {
      this.loadingHide = null;
    }
    if (info.file.status === 'uploading') {
      if (!this.loadingHide) {
        this.loadingHide = message.loading("简历解析中...");
      }
    }
    if (info.file.status === 'done') {
      this.loadingHide && this.loadingHide();
      this.loadingHide = null;
      let res = info.file.response;
      if (res.code === 0) {
        this.setState({
          uploadResume: res.data,
          uploadResumeModal: true,
        })
      } else {
        message.error(res.msg);
      }
    } else if (info.file.status === 'error') {
      this.loadingHide && this.loadingHide();
      this.loadingHide = null;
      message.error(`${info.file.name}上传失败`);
    }
  }

  onPerpact=(e)=>{
    if(!(this.props.user||{}).intent_is_complete){
      this.setState({isOpenSel:true})
      message.warning("请完善求职意向",()=>{
        this.setState({isOpenSel:false})
      });
      return false;
    }
  }
  render() {
    const _this = this;
    const { history, resume, editResume, delResume, updateResumeIsEdit, user } = this.props;
    const { profile_option, uploadResume, uploadResumeModal, isOpen } = this.state;
    const uploadProps = {
      name: 'attachment',
      action: '/cpc/v1/profile/analysis',
      showUploadList: false,
      onChange: this.onChange,
    };
    
    const resumeAction = {delResume, editResume, updateResumeIsEdit};
    let completeRatio = resume.complete_ratio ? parseInt(resume.complete_ratio.split("%")[0]) : 0;
    return (
      <div className="resume-content" >
        {/* <SecondTabs history={history}/> */}
        <div className="resume-wrapper">
          <div className="resume-left">
            <div className="resume-left-header">
              <span className="title">中文简历</span>
            </div>
            <BaseInfo resume={resume} options={profile_option} resumeAction={resumeAction} user={user}/>
            <Intent resume={resume} options={profile_option} resumeAction={resumeAction} user={user}/>
            <Work resume={resume} options={profile_option} resumeAction={resumeAction}/>
            <Project resume={resume} options={profile_option} resumeAction={resumeAction}/>
            <Education resume={resume} options={profile_option} resumeAction={resumeAction}/>
          </div>
          <Anchor offsetTop={50}>
          <div className="resume-right">
            <div className="upload-panel">
              <Upload {...uploadProps}>
                <Button type="primary" className="upload-btn"><CustomIcon type="icon-cloud-upload" style={{fontSize: "16px", position: "relative", top: "1px"}}/>上传简历附件</Button>
              </Upload>
            </div>
            <div className="progress-panel">
              <div className="degree">
                <div className="des">简历完整度：<span className={`num ${completeRatio < 50 ? 'red' : ''}`}>{resume.complete_ratio ? resume.complete_ratio : "0%"}</span>
                  <span className={`tip ${completeRatio < 50 ? 'red' : ''}`}>
                    {completeRatio < 50 ? '待完善' : ''}
                    {completeRatio >= 50 && completeRatio < 100 ? '继续完善' : ''}
                  </span>
                </div>
                <Progress className="complete-ratio-progress" size="small" percent={resume.complete_ratio ? parseInt(resume.complete_ratio.split("%")[0]) : 0} showInfo={false}/>
              </div>
              
              <div className="need">
                <div className="tip">必填项</div>
                <Link href="#baseinfo" title={<div className="item"><CustomIcon type="icon-tongxunlu" />基本信息</div>}></Link>
                <Link href="#intent" title={<div className="item"><CustomIcon type="icon-signboard"/>求职意向</div>}></Link>
              </div>
              <div className="need">
                <div>加分项</div>
                <Link href="#work" title={<div className="item"><CustomIcon type="icon-bags"/>工作经历</div>}></Link>
                <Link href="#project" title={<div className="item"><CustomIcon type="icon-brand"/>项目经验</div>}></Link>
                <Link href="#edu" title={<div className="item"><CustomIcon type="icon-shengyijing"/>教育经历</div>}></Link>
              </div>
            </div>
            <div className="open-panel">
              <span className="open-tip">隐私设置</span>
              {/* <Switch checked={isOpen} className="switch-bar" onChange={this.openChange}/> */}
              <Select onChange={this.openChange} value={isOpen} placeholder="请选择隐私设置" disabled={this.state.isOpenSel} onDropdownVisibleChange={(e)=>this.onPerpact(e)}>
                {
                  profile_option && profile_option.is_open_resume && profile_option.is_open_resume.map(item => {
                    return <Option key={item.value} value={item.value} title={item.label}>{item.label}</Option>
                  })
                }
              </Select>
            </div>
          </div> 
          </Anchor>     
        </div>
        <UploadResumeModal visible={uploadResumeModal} onClose={this.closeUploadMolda} resume={uploadResume} resumeAction={resumeAction}  options={profile_option}/>
      </div>
    );
  }
}
