import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Select, message, Input, Row, Col, Radio, DatePicker, Modal, Steps, Empty } from "antd";
import {LoginPanel} from "./login";
import {RegisterPanel} from "./register";
import {ResetPanel} from "./resetPassword";
import { useDispatch } from "react-redux";

const { Step } = Steps;
const FormItem = Form.Item;
const size = "large";

export default function LoginModal(props) {
  const { history, visible, userAction } = props;
  const [panelView, setPanelView] = useState(1); // 1登录 2注册 3 忘记密码
  const [loginType, setLoginType] = useState("1"); // 1验证码登录 2zhanghao
  const dispatch = useDispatch();

  const changeView = (view, loginType) => {
    setPanelView(view);
    loginType && setLoginType(loginType);
  }
  const onClose = () => {
    dispatch({ type: "user/closeLoginModal", payload: false });
  }

  return (
    <Modal width={750} title="登录" className="login-modal" 
      footer={null} onCancel={onClose} destroyOnClose visible={visible} >
      <div className="user-panel" style={{height: panelView === 3 ? "545px" : "432px"}}>
        <div className="panel-left">
          <div className="fes-item">
            <div className="">
              <img src={require("assets/imgs/auth/fes-feedback.png")}/>
            </div>
            <div>
              <div className="title">反馈快</div>
              <div className="tip">职位投递高效沟通</div>
            </div>
          </div>
          <div className="fes-item">
            <div className="">
              <img src={require("assets/imgs/auth/fes-all.png")}/>
            </div>
            <div>
              <div className="title">任性选</div>
              <div className="tip">500强名企职位在线投递</div>
            </div>
          </div>
          <div className="fes-item">
            <div className="">
              <img src={require("assets/imgs/auth/fes-well.png")}/>
            </div>
            <div>
              <div className="title">服务好</div>
              <div className="tip">职位顾问精准推荐</div>
            </div>
          </div>
        </div>
        <div className="panel-right">
          {panelView === 1 && <LoginPanel tabType={loginType} history={history} userAction={userAction} modalClick={changeView}/>}
          {panelView === 2 && <RegisterPanel history={history} userAction={userAction} modalClick={changeView}/>}
          {panelView === 3 && <ResetPanel history={history} userAction={userAction} modalClick={changeView}/>}
        </div>
      </div>
    </Modal>
  );
}
