import Model from "../Model";
import * as serveProgressS from "services/serveProgressS";

export default Model.getInstance(
  class extends Model {
    namespace = "ServeProgressModal";
    state = {
      paramProfiles: {
        status: 99,
        page: 1,
        pages: 7
      }, //人选列表参数
      paramRecmmend: {
        status: 0,
        page: 1,
        pages: 4
      }, //推荐参数
      positionInfo: {}, //职位信息
      option: {}, //职位选项
      profileList: {}, //候选人列表
      RecmmendList: {}, //推荐人选列表
      progressInfo: {} //服务进展信息
    };

    actions = {
      async getPositionDetail(param) {
        let result = await serveProgressS.positionDetail(param);
        if (result.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "positionInfo", value: result.data }
          });
        }
        return result;
      },
      async getPositionProfile(type) {
        const {
          paramProfiles,
          paramRecmmend
        } = this.getState().ServeProgressModal;
        const param = {
          profileList: paramProfiles,
          RecmmendList: paramRecmmend
        };
        let result = await serveProgressS.positionProfilesList(param[type]);
        if (result.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: [type], value: result.data }
          });
        }
        return result;
      },
      async onPositionEdit(param) {
        let result = await serveProgressS.positionEdit(param);
        return result;
      },
      async onServeStop(param) {
        let result = await serveProgressS.isServeStop(param);
        return result;
      },
      async onConfirmQuote(param) {
        let result = await serveProgressS.confirmQuote(param);
        return result;
      },
      async getServeProgress(param) {
        let result = await serveProgressS.serveProgress(param);
        if (result.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "progressInfo", value: result.data }
          });
        }
        return result;
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
