import xhr from "./index";

// 人选列表
export function getProfiles(param) {
  return xhr({ url: "/v4_0/profile/list", body: param, method: "POST" });
}

// 人选详情
export function getProfileDetail(param, cookie) {
  return xhr({ url: "/v4_0/profile/info", body: param, method: "GET" }, cookie);
}

// 实习生详情
export function getInternDetail(param) {
  return xhr({ url: "/v4_0/profile/infoIntern", body: param, method: "GET" });
}

// 人选反馈
export function sendFeedback(param) {
  return xhr({ url: "/v4_0/center/feedback", body: param, method: "POST" });
}

// 职位列表
export function getJDList(param) {
  return xhr({ url: "/v4_0/jd/list", body: param, method: "GET" });
}

// 添加职位
export function addPosition(param) {
  return xhr({ url: "/v4_0/jd/submit", body: param, method: "POST" });
}

// 我的人才库
export function getMyProfiles(param) {
  return xhr({ url: "/v4_0/center/myProfile", body: param, method: "POST" });
}

// 收藏取消收藏
export function operateCollection(param) {
  return xhr({ url: "/v4_0/center/collection", body: param, method: "POST" });
}

// 收藏取消收藏
export function reserve(param) {
  return xhr({ url: "/v4_0/jd/interview", body: param, method: "POST" });
}

export function downloadResume(param) {
  return xhr({ url: "/v4_0/profile/download", body: param, method: "GET" });
}

export function forwardResume(param) {
  return xhr({ url: "/v4_0/profile/forward", body: param, method: "POST" });
}

// 实习生详情
export function getProfileImg(param) {
  return xhr({ url: "/v4_0/home/indexImg", body: param, method: "GET" });
}