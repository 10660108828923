
import React, { useState, useEffect, forwardRef } from 'react';
import ReactDOM from "react-dom";
import { Modal, Form, Input, message, Tag } from 'antd';
import { Link } from "react-router-dom";
import { getCommonData } from "services/commonService";
import './style.scss';
const { CheckableTag } = Tag;
const { TextArea } = Input;

export default function MenuSelection(props = {}) {
    const { onChange, value } = props;
    const [options, setOptions] = useState();
    console.log(value, 'value')
    useEffect(() => {
        setOptions([...JSON.parse(JSON.stringify(props.options))]);
    }, [props.options])
    function onCheckout(item, parentItem) {
        console.log('item', item, props)
        const { onClick } = props;
        onClick && onClick(item, parentItem)
    }
    return (
        <React.Fragment>
            <div className="menu-container">
                <ul>
                    {options && options.map((item) => {
                        return (
                            <li key={item.value}>
                                <div className="label"><span>{item.label}</span> <span className="arrow" /></div>
                                <div className="sub-nav">
                                    <div className="title">{item.label}</div>
                                    {item.list && item.list.map((itemNav, index) => {
                                        return (
                                            <span key={itemNav.value}>
                                                <Link className="link" onClick={(e) => {
                                                    onCheckout(itemNav, item)
                                                    e.stopPropagation();
                                                    e.preventDefault()
                                                }} to="" key={itemNav.value} >{itemNav.label}</Link>
                                                {index < item.list.length - 1 && <span className="line" />}
                                            </span>
                                        )
                                    })}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </React.Fragment>
    )
}