import React, { useState, useRef } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import useIsLogin from "hooks/useIsLogin";
import { sendMsg } from "services/userService";
import { useDispatch } from "react-redux";
import "./authPanel.scss";
const FormItem = Form.Item;
const size = "default";

function LoginForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history } = props;
  const [status, setStatus] = useState(61)

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values, history, userAction);
        if (!new RegExp(/^1[3456789]\d{9}$/).test(values.phone)) {
          message.error("请输入正确的手机号")
          return;
        }
        if (!values.phone) {
          message.error("请输入手机号");
          return;
        }
        if (!values.verify_code) {
          message.error("请输入验证码");
          return;
        }
        userAction.phoneLogin({...values, register: 1}).then(res => {
          if (res.code === 0) {
            history.push("/center");
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }
  const getCode = () => {
    let phone = getFieldValue("phone");
    if (!new RegExp(/^1[3456789]\d{9}$/).test(phone)) {
      message.warning("请输入正确的手机号")
      return;
    }
    let count = 60;
    setStatus(count--);
    let countTime = setInterval(() => {
      if (count === 0) {
        setStatus(61);
        clearInterval(countTime);
        return;
      }
      setStatus(count--);
    }, 1000)
    sendMsg({
      phone: phone
    })
  }

  return (
    <Form className="quick-form" layout="inline" onSubmit={handleSubmit}>
      <FormItem className="form-item" label="">
        {getFieldDecorator("phone", {
          // initialValue: editClueData.company_name,
          rules: [
            // { required: true, message: "请输入手机号" },
            // { pattern: new RegExp(/^1[3456789]\d{9}$/), message: "手机号码格式不正确" },
          ]
        })(
          <Input
            // onBlur={this.onBlurHandle}
            placeholder="请输入手机号"
            size={size}
          />
        )}
      </FormItem>
      {/* <div className="special-input"> */}
        <FormItem className="form-item" label="">
          {getFieldDecorator("verify_code", {
            // initialValue: editClueData.company_name,
            // rules: [
            //   { required: true, message: "请输入手机验证码" },
            // ]
          })(
            <Input
              // onBlur={this.onBlurHandle}
              placeholder="请输入手机验证码"
              size={size}
              maxLength={6}
              suffix={<div className="get-phone-code">
                <span className="splite">|</span>
                {status === 61 ? <span onClick={getCode}>获取</span> : <span className="count-time">{status}s</span>}
              </div>}
            />
          )}
        </FormItem>
        {/* <PhoneCode className="register-get-code" formRef={props.form} userAction={userAction} /> */}
      {/* </div> */}
      <Button className="login-btn" type="primary" htmlType="submit">登录/注册</Button>
    </Form>
  );
}

export default function AuthPanel(props) {
  const {userAction, history} = props;
  const Login = Form.create({})(LoginForm);

  return (
    <div className="auth-panel-wrapper">
      <div className="auth-panel-inner">
        <Login userAction={userAction} history={history}></Login>
        <div className="user-agreement" onClick={() => window.open("/agreement")}>*注册即代表你同意用户服务协议</div>
      </div>
    </div>
  );
}
