import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, Form, Select, message, Input, Row, Col, Cascader, DatePicker, Spin } from "antd";
import {positionSearch} from "services/commonService";
import CustomIcon from "components/CustomIcon";
import moment from "moment";
import MultiCascade from "components/MultiCascade";
import { useOperateView } from "./resume-util";

const FormItem = Form.Item;
const { Option } = Select;
const size = "large";

function createMarkup(html = "") {
  if (!html) html = "";
  console.log(html);
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}

export const IntentForm = forwardRef((props, ref) =>  {
  const { getFieldDecorator, getFieldValue, validateFieldsAndScroll } = props.form;
  const { resumeAction, changeView, history, isUpload, form, resume, viewStatus,
      options = { basic: {}, intent: {} } } = props;
  const [positions, setPositions] = useState([]);
  const [fetching, setFetching] = useState(false);
  useImperativeHandle(ref, () => ({
    form, onSubmit
  }));

  const overOperate = useOperateView(resume, false, () => changeView(3));

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        values = processValues(values)
        console.log("Received values of form: ", values, history);
        let param = {};
        if(values.expect_positions && values.expect_positions.length !== 0) {
          values.expect_position_id = values.expect_positions[1];
        }
        param.intent = {...values};
        resumeAction.editResume(param).then(res => {
          if (res.code === 0) {
            overOperate();
          } else {
            message.error(res.errorMsg);
          }
        });
      }
    });
  };

  const onSubmit = () => {
    return new Promise((resolve, reject) => {
     validateFieldsAndScroll((err, values) => {
        if (!err) {
          values = processValues(values)
          resolve(values);
        } else {
          reject(err);
        }
      });
    });
  };

  let timeout;
  let currentValue;
  const fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    if (!value) return;
    const _this = this;
    currentValue = value;
    function fake() {
      setFetching(true);
      positionSearch({ keyword: value }).then(res => {
        if (currentValue === value && res.code === 0) {
          const result = res.data || [];
          const data = [];
          result.forEach(item => {
            data.push({
              value: item,
              label: item,
            });
          });
          callback(data);
        }
        setFetching(false);
      });
    }
    timeout = setTimeout(fake, 500);
  };

  const handleSearch = value => {
    fetch(value, options =>setPositions(options));
  };

  const processValues = (values) => {
    if (values.expect_positions && values.expect_positions.length !== 0) {
      values.expect_position_id = values.expect_positions[1];
    }
    values.city_id = values.cityids.map(item => item.city).filter(item => item);
    return values;
  }

  let initCitys = resume.city ?  resume.city.map(item => {
    return {
      province: item.province_id,
      city: item.id,
      city_label: item.name,
    }
  }) : [];
  
  return (
    <Form className="resume-form intent-form-content" onSubmit={handleSubmit}>
        {!isUpload && <div className="form-header">
            {viewStatus === 2 ? "添加求职意向" : ""}
            {viewStatus === 1 ? "编辑求职意向" : ""}
          </div>
        }
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="工作地点">
              {getFieldDecorator("cityids", {
                initialValue: initCitys,
                rules: [
                  { required: false, message: "请选择工作地点" },
                ]
              })(
                <MultiCascade
                  showSearch={true}
                  placeholder="请选择工作地点" size={size}
                  options={options.basic ? options.basic.cities_two_level : []} 
                  fieldNames={{ label: 'name', value: 'id', children: 'list' }}
                  />
              )}
            </FormItem>
          </Col>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="期望职位">
              {getFieldDecorator("expect_position", {
                initialValue: resume.expect_position,
                rules: [
                  { required: true, message: "请输入期望职位" },
                ]
              })(
                // <Input
                //   // onBlur={this.onBlurHandle}
                //   placeholder="请输入期望职位"
                //   size={size}
                // />
                <Select
                  allowClear
                  size={size}
                  showSearch
                  placeholder="请输入"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearch}
                  notFoundContent={ fetching ? <Spin size="small" /> : null}>
                  {positions.map((item, index) => (
                    <Option key={index} value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="期望薪资">
              {getFieldDecorator("expect_salary", {
                initialValue: resume.expect_salary ? resume.expect_salary : undefined,
                rules: [
                  { required: true, message: "请选择期望薪资" },
                ]
              })(
                <Select placeholder="请选择期望薪资" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.intent.expect_salary && options.intent.expect_salary.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="工作类型">
              {getFieldDecorator("work_type", {
                initialValue: resume.work_type ? resume.work_type : undefined,
                // rules: [{ required: true, message: "请选择工作类型" }]
              })(
                <Select placeholder="请选择工作类型" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.intent.work_type && options.intent.work_type.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="行业">
              {getFieldDecorator("industry", {
                initialValue: resume.industry ? resume.industry : undefined,
                // rules: [
                //   { required: true, message: "请选择行业" },
                // ]
              })(
                <Select placeholder="请选择行业" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.intent.industry && options.intent.industry.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="到岗时间">
              {getFieldDecorator("arrival", {
                initialValue: resume.arrival ? resume.arrival : undefined,
                // rules: [
                //   { required: true, message: "请选择到岗时间" },
                // ]
              })(
                <Select placeholder="请选择到岗时间" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.intent.arrival && options.intent.arrival.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="是否接受外包">
              {getFieldDecorator("outsourcing", {
                initialValue: resume.outsourcing ? resume.outsourcing : undefined,
                // rules: [
                //   { required: true, message: "请选择到岗时间" },
                // ]
              })(
                <Select placeholder="请选择是否接受外包" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.intent.outsourcing && options.intent.outsourcing.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
          <Col  className="resume-form-col">
              <FormItem className="form-item" label="职能">
                {getFieldDecorator("expect_positions", {
                  initialValue: [resume.expect_position_p_id, resume.expect_position_id].filter(item=>item),
                  rules: [{ required: true, message: "请选择职能" }]
                })(
                  <Cascader placeholder="请选择职能" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}
                    options={options.function_type_option ? options.function_type_option : []} 
                    displayRender={(label, selectedOptions) => label[1]}
                    fieldNames={{ label: 'label', value: 'value', children: 'list' }}
                    />
                )}
              </FormItem>
          </Col>
        </Row>
        <Row>
          <FormItem className="form-item" label="自我评价">
              {getFieldDecorator("self_apprisal", {
                initialValue: resume.self_apprisal,
                // rules: [{ required: true, message: "请输入自我评价" }]
              })(
                <Input.TextArea rows={6}
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入自我评价"
                />
              )}
            </FormItem>
        </Row>
        {!isUpload && 
          <Row className="operate-row clear">
            <Button className="save-btn" type="primary" size={size} htmlType="submit">保存</Button>
            <Button className="cancel-btn" size={size} onClick={overOperate}>取消</Button>
          </Row>
        }
    </Form>
  );
})

export function IntentView(props) {
  const { changeView, resume } = props;
  
  return (
    <div className="intent-view-content">
      <div className="intent-info">
        <div className="ellipsis" title={resume.expect_position}>期望职位：{resume.expect_position ? resume.expect_position : "-"}</div>
        <div className="last ellipsis" title={resume.arrival_name}>到岗时间：{resume.arrival_name ? resume.arrival_name : "-"}</div>
      </div>
      <div className="intent-info">
        <div className="ellipsis" title={resume.expect_salary_name}>期望薪资：{resume.expect_salary_name ? resume.expect_salary_name : "-"}</div>
        <div className="last ellipsis" title={resume.industry_name}>从事行业：{resume.industry_name ? resume.industry_name : "-"}</div>
      </div>
      <div className="intent-info">
        <div className="ellipsis" title={resume.work_type_name}>工作类型：{resume.work_type_name ? resume.work_type_name : "-"}</div>
        <div className="last ellipsis" title={resume.outsourcing_name}>接受外包：{resume.outsourcing_name ? resume.outsourcing_name : "-"}</div>
      </div>
      <div className="intent-info">
        <div className="ellipsis" title={resume.expect_position_name}>职能：{resume.expect_position_name ? resume.expect_position_name : "-"}</div>
        {/* <div className="last ellipsis" title={resume.outsourcing_name}>接受外包：{resume.outsourcing_name ? resume.outsourcing_name : "-"}</div> */}
      </div>
      {resume.self_apprisal && 
        <React.Fragment>
          <div className="self">自我评价</div>
          <div className="self-info" title={resume.self_apprisal} dangerouslySetInnerHTML={createMarkup(resume.self_apprisal)}></div>
        </React.Fragment>
      }
    </div>
  );
}

export const IntentFormWrapper = Form.create({})(IntentForm);

export default function Intent(props) {
  const { user, resumeAction, options, resume } = props;
  const [viewStatus, setViewStatus] = useState(3); // 1编辑2添加3视图

  const changeView = (viewStatus) => {
    setViewStatus(viewStatus)
  }

  // useEffect(() => {
  //   if (user.complete_ratio !== "0%") {
  //     setViewStatus(3);
  //   } else {
  //     setViewStatus(2);
  //   }
  // }, [user.complete_ratio]);
  
  let intents = resume.intents && resume.intents.length !== 0 ? resume.intents[0] : {};
  const editOperate = useOperateView(resume, true, () => changeView(1));
  const addOperate = useOperateView(resume, true, () => {
    if (!resume.complete_ratio){
      message.warning("还有未编辑完的项目");
      return;
    }
    changeView(2)
  });
  return (
    <div className="form-wrapper resume-item-wrapper intent-wrapper">
      <a id="intent"></a>
      {viewStatus === 3 && 
        <div className="resume-item-header">
          <span className="title-line"></span>
          <span className="head-title">求职意向</span>
          {resume.intents && resume.intents.length !== 0 ?
            <div className={`edit-area ${resume.resumeIsEdit ? "disable" : ''}`} onClick={editOperate}><img className="edit-img" src={require("assets/imgs/resume/icon-edit.png")}/>编辑</div>
            :
            <div className={`add-area ${resume.resumeIsEdit ? "disable" : ''}`} onClick={addOperate}><img src={require('assets/imgs/resume/icon-add.png')}/>添加</div>
          }
        </div>
      }
      {(viewStatus === 1 || viewStatus === 2) && 
        <IntentFormWrapper changeView={changeView} viewStatus={viewStatus} resume={intents} options={options} resumeAction={resumeAction}/> }
      {viewStatus === 3 && resume.intents && resume.intents.length !== 0 && <IntentView changeView={changeView} resume={intents}/> }
    </div>
  );
}
