import React, { useState, useRef } from "react";
import { Modal, Divider } from "antd";
import CustomIcon from "components/CustomIcon";
import useIsLogin from "hooks/useIsLogin";
import scrollTo from "utils/scrollTo"
import "./Card.scss";

const { info } = Modal;
export function CompanyCard(props) {
  const { item = {} } = props;
  const isLogin = useIsLogin();
  const scrollTopWindow = () => {
    if (isLogin) {
    } else {
      scrollTo(0)
    }
  }
  const {company={}}=item;
  return (
    <div className="position-card card-style" {...props}>
      <div className="row">
        <div className="position ellipsis" title={item.name}>{item.name}</div>
        <div className="salary ellipsis" title={item.original_salary}>{item.original_salary}</div>
      </div>
      <div className="base ellipsis" title={item.joint}>{item.joint}</div>
      <Divider dashed={true} />
      <div className="company">
        <img src={company.logo} />
        <div className="ellipsis" title={company.name}>{company.name}</div>
      </div>
    </div>
  )
}


