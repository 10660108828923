import React from "react";
import { Button } from "antd";
import "./abnormal.scss";

export class Abnormal404 extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div className="abnormal404-box bg">
        <div className="container">
          <div className="abnormal-show-box clear">
            <div className="left-show">
              <div className="img-box">
                <div className="img404" />
              </div>
            </div>
            <div className="right-show">
              <div className="title">404</div>
              <div className="des">抱歉，你访问的页面不存在</div>
              <div className="btn">
                <Button type="primary" onClick={() => history.push("/")}>
                  返回首页
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export class Abnormal500 extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div className="abnormal500-box bg">
        <div className="container">
          <div className="abnormal-show-box clear">
            <div className="left-show mg">
              <div className="img-box">
                <div className="img500" />
              </div>
            </div>
            <div className="right-show">
              <div className="title">500</div>
              <div className="des">抱歉，服务器出错了</div>
              <div className="btn">
                <Button type="primary" onClick={() => history.push("/")}>
                  返回首页
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * fallback UI for client error
 */
export class ClientErrorFallback extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <div className="abnormal500-box bg">
        <div className="container">
          <div className="abnormal-show-box clear">
            <div className="left-show mg">
              <div className="img-box">
                <div className="img500" />
              </div>
            </div>
            <div className="right-show">
              <div className="title">错误</div>
              <div className="des">抱歉，客户端发送未知错误</div>
              <div className="btn">
                <Button type="primary" onClick={() => history.push("/")}>
                  返回首页
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}