import React, { useState, useRef } from "react";

import "./AuthBg.scss";

export default function AuthBg(props) {
  const {history} = props;
  return (
    <div className="auth-bg">
      <img onClick={() => history.push("/")} className="g-logo" src={require("assets/imgs/auth/g-logo.png")} alt="logo"/>
      <div className="auth-panel user-panel" style={{height: props.height ? (props.height + "px") : "432px"}}>
        <div className="panel-left">
          <div className="fes-item">
            <div className="">
              <img src={require("assets/imgs/auth/fes-feedback.png")}/>
            </div>
            <div>
              <div className="title">反馈快</div>
              <div className="tip">职位投递高效沟通</div>
            </div>
          </div>
          <div className="fes-item">
            <div className="">
              <img src={require("assets/imgs/auth/fes-all.png")}/>
            </div>
            <div>
              <div className="title">任性选</div>
              <div className="tip">500强名企职位在线投递</div>
            </div>
          </div>
          <div className="fes-item">
            <div className="">
              <img src={require("assets/imgs/auth/fes-well.png")}/>
            </div>
            <div>
              <div className="title">服务好</div>
              <div className="tip">职位顾问精准推荐</div>
            </div>
          </div>
        </div>
        <div className="panel-right">
          {props.children}
        </div>
      </div>
      
    </div>
  )
}