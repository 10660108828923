// import React from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";

export function useOperateView(resume, status = false, callback) {
  const dispatch = useDispatch();
  return () => {
    if (resume.resumeIsEdit && status) {
      // message.warning("还有未编辑完的项目");
      return;
    }
    if (callback) callback();
    dispatch({ type: "resumeModel/updateResumeIsEdit", payload: status });
  };
};
