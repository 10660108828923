import App, { configureStore, staticRoutes } from "./app";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { ConfigProvider } from "antd";
import { RouteDataLoader } from "utils/hoc/route-data-loader";
import history from "utils/history";
import InitAppData from "utils/hoc/init-app-data";
import errorBoundary from "utils/hoc/errorBoundary";
import { ClientErrorFallback } from "pages/abnormal/abnormal";
import { PathnameRecord } from "utils/hoc/pathname-record";
import CustomizeRenderEmpty from "components/CustomizeRenderEmpty";
import zhCN from 'antd/es/locale/zh_CN';
// let storedefa;
// if (typeof document !== 'undefined') {
//   storedefa = window ? window.__PRELOADED_STATE__  : {}
// }
export const store = configureStore.createStore(window.__PRELOADED_STATE__);
const ErrorBoundary = errorBoundary(() => (
  <ClientErrorFallback history={history} />
));

export default function root() {
  return (
    <Provider store={store}>
      <InitAppData>
        <Router history={history}>
          <RouteDataLoader routes={staticRoutes} store={store}>
            <PathnameRecord store={store}>
              <ErrorBoundary>
                <ConfigProvider renderEmpty={CustomizeRenderEmpty} locale={zhCN}>
                  <App />
                </ConfigProvider>
              </ErrorBoundary>
            </PathnameRecord>
          </RouteDataLoader>
        </Router>
      </InitAppData>
    </Provider>
  );
}
