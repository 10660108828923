import React from "react";
import { connect, useDispatch } from "react-redux";
import { Pagination, Empty, Button, Tabs, Divider, Input, Cascader, Radio } from "antd";
import CustomIcon from "components/CustomIcon";
import PositionCard from "components/PositionCard/PositionCard";
import userModel from "store/reducers/userModel";
import positionModel from "store/reducers/positionModel";
import scrollTo from "utils/scrollTo"
import CascaderMore from "components/CascaderMore/CascaderMore";
import * as indexService from "services/indexService";
import { unique } from "utils/util";
import { getCommonData } from "services/commonService";
import "./positionList.scss"

const { TabPane } = Tabs;
@connect(
  ({ index, user, positionModel }) => {
    return { home: index, user: user, ...positionModel };
  },
  {
    // ...userModel.actions,
    ...positionModel.actions
  }
)
export default class PositionList extends React.Component {
  state = {
    function_type: [],
    cascaderMoreOptions: [],
  }
  componentDidMount() {
    document.title = '找工作-专注人事财务工作-即派优职';
    scrollTo(0);
    const { setUpdateState, hostCityOption, params } = this.props;
    const { citySel = {} } = params;

    this.onSearch();
    getCommonData(['function_type,cities']).then(res => {
      console.log(222, res)
      this.setState({
        function_type: res.function_type,
        cascaderMoreOptions: [
          { label: "热门城市", data: res.cities.hots },
          {
            label: "全部省份", data: res.cities.city
          }
        ]
      })
      //为了显示首页过来的城市选中
      let option = [];
      if (citySel.id === 1) {
        option = [].concat(res.cities.hots)
      } else {
        option = [citySel].concat(res.cities.hots)
      }
      setUpdateState({
        filed: "hostCityOption",
        value: unique(option)
      });
    })

  }
  componentDidUpdate(prevProps) {
    // const { type } = this.props;
    // if (type !== prevProps.type) {
    //   console.log("eeeee", type)
    //   this.onSearch();
    // }
  }
  componentWillUnmount() {

  }
  onSearch = (val = "", name = "") => {
    const { getList, setUpdateState, params } = this.props;
    let paramsChange = { ...params }
    if (name && val) {
      if (name === "keyword") {
        paramsChange[name] = val.target.value;
      } else if (name === "function_type") {
        paramsChange[name] = val[val.length - 1]
      } else {
        paramsChange[name] = val;
      }

    }
    setUpdateState({
      filed: "params",
      value: paramsChange
    });
    getList(paramsChange)
  }
  // 选择城市
  onCity = (val = "", name = "", type = 0) => {
    const { setUpdateState, params, hostCityOption } = this.props;
    let paramsChange = { ...params }
    if (type === 1) {
      paramsChange[name] = this.getObj(hostCityOption, val.target.value);
    } else {
      paramsChange[name] = val;
    }
    setUpdateState({
      filed: "params",
      value: paramsChange
    });
  }
  // 更多---选择的城市
  onCascaderCity = (val = []) => {
    const { setUpdateState, params, hostCityOption } = this.props;
    //删除之前选中的
    if (hostCityOption.length == 6) hostCityOption.splice(0, 1)
    let option = val.concat(hostCityOption)
    console.log('option', option)
    setUpdateState({
      filed: "hostCityOption",
      value: unique(option)
    });
    this.onCity(val[0], 'citySel');
  }
  handlePage = (page = 1, pageSize = 10) => {
    const { getList, setUpdateState, params } = this.props;
    let paramsChange = {
      ...params,
      page: page,
      "per-page": pageSize
    }
    setUpdateState({
      filed: "params",
      value: paramsChange
    });
    getList(paramsChange)
  }
  // 获取选中的id对象
  getObj = (data = [], id) => {
    let obj = {}
    data.map(item => {
      if (id === item.id) obj = item;
    })
    return obj;
  }
  displayRender = (label) => {
    // return label[label.length - 1];
    return label
  }
  go = (item) => {
    console.log('ttt', this.props)
    // this.props.history.push("/positionDetail/" + item.id)
    window.open("/positionDetail/" + item.id)

  }


  render() {
    const { history, dataObj, type, params = {}, hostCityOption = [] } = this.props;
    const { list = [], _meta = {} } = dataObj;
    const { cascaderMoreOptions = [], } = this.state;
    const { citySel = {}, keyword, defaultType } = params;

    console.log(12, params, this.props)
    return (
      <div className="position-list container-g">
        <div className="left-side">
          <div className="search-left">
            <div className="row">
              <div className="label">职位名称</div>
              <Input onBlur={(e) => this.onSearch(e, 'keyword')} defaultValue={keyword} placeholder="请输入职位搜索的关键词" />
            </div>
            <div className="row">
              <div className="label">职能分类</div>
              <Cascader
                placeholder="请选择职能分类"
                options={this.state.function_type}
                expandTrigger="hover"
                // displayRender={this.displayRender}
                onChange={(e) => this.onSearch(e, 'function_type')}
                defaultValue={params.function_type ? [(params.defaultType || {}).value, params.function_type] : []}
                fieldNames={{
                  label: "label",
                  value: "value",
                  children: "list"
                }}
              />
            </div>
            <div className="row">
              <div className="label">
                <div>选择地点</div>
                <div> <CascaderMore onChange={(e) => this.onCascaderCity(e)} options={cascaderMoreOptions} type="3" number="1" single={true} value={[citySel]} /></div>
              </div>
              <div className="sel-box">
                <Radio.Group onChange={(e) => this.onCity(e, 'citySel', 1)} defaultValue={(citySel || {}).id} value={(citySel || {}).id}>
                  {hostCityOption.map(item => {
                    return (<Radio.Button key={item.id} value={item.id}>{item.name}</Radio.Button>)
                  })}
                </Radio.Group>
              </div>
            </div>
            <div className="btn">
              <Button type="primary" onClick={() => { this.onSearch() }}>搜索</Button>
            </div>
          </div>
        </div>
        <div className="right-side-show">
          <div className="sort">
            <div onClick={() => { this.onSearch('publish_time', 'order_by') }} className={`${params.order_by === "publish_time" && 'active'}`}>发布时间排序</div>
            <Divider type="vertical" />
            <div onClick={() => { this.onSearch('update_time', 'order_by') }} className={`${params.order_by === "update_time" && 'active'}`}>综合排序</div>
          </div>
          <Tabs animated={false} activeKey={"1"}>
            <TabPane tab="职位" key="1" />
          </Tabs>
          {list.map((item, index) => {
            return <PositionCard onClick={() => { this.go(item) }} key={item.id} item={item} type={type} />
          })}
          <Pagination current={_meta.currentPage || 1} pageSize={_meta.perPage || 10}
            total={_meta.totalCount} className="pagination" hideOnSinglePage={true}
            onChange={this.handlePage} />
          {!list.length && <div className="empty"><img src={require("assets/imgs/empty.png")} /> <div>暂无数据</div></div>}
        </div>
      </div>
    )
  }
}
