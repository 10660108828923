import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, Form, Select, message, Input, Row, Col, Modal, DatePicker } from "antd";
import moment from "moment";
import { useOperateView } from "./resume-util";
import CustomIcon from "components/CustomIcon";

const FormItem = Form.Item;
const size = "large";
const { Option } = Select;
const { MonthPicker } = DatePicker;

function createMarkup(html = "") {
  if (!html) html = "";
  console.log(html);
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}

export const WorkForm = forwardRef((props, ref) => {
  const { getFieldDecorator, getFieldValue, validateFieldsAndScroll } = props.form;
  const { commonResume, resumeAction, changeView, history, isLast, index,
    isUpload, form, resume, viewStatus, options = { basic: {}, intent: {}, job: {} } } = props;
  useImperativeHandle(ref, () => ({
    form, onSubmit
  }));
  const cancelView = () => {
    if (viewStatus === 1) {
      changeView(3);
    } else if (viewStatus === 2) {
      resumeAction.removeView();
    }
  }
  const overOperate = useOperateView(commonResume, false, () => cancelView());

  const delOperate = () => {
    Modal.confirm({
      title: '确定要删除该工作经历吗?',
      okText: '确定',
      cancelText: "取消",
      onOk() {
        resumeAction.removeView("work", index);
      },
      onCancel() {
      },
    })
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.id = resume.id;
        values = processValues(values)
        console.log("Received values of form: ", values, history);
        let param = {};
        param.jobs = [{...values}];
        resumeAction.editResume(param).then(res => {
          if (res.code === 0) {
            overOperate();
          } else {
            message.error(res.errorMsg);
          }
        });
      }
    });
  };

  const onSubmit = () => {
    return new Promise((resolve, reject) => {
     validateFieldsAndScroll((err, values) => {
        if (!err) {
          values = processValues(values)
          resolve(values);
        } else {
          reject(err);
        }
      });
    });
  };

  const processValues = (values) => {
    if (values.start_date) 
      values.start_date = values.start_date.format("YYYY-MM");
    if (values.end_date) 
      values.end_date = values.end_date.format("YYYY-MM");
    return values;
  }

  const disabledStartDate = (current) => {
    let end_date = getFieldValue("end_date");
    if (!current) {
      return false;
    }

    if (!end_date) {
      return current.valueOf() > moment().valueOf();
    }
    return (
      current.valueOf() > end_date.valueOf() ||
      current.valueOf() > moment().valueOf()
    );
  }

  const disabledEndDate = (current) => {
    let start_date = getFieldValue("start_date");
    if (!current) {
      return false;
    }
    if (!start_date) {
      // 如果没有选开始时间, 则可选时间不能大于当前时间
      return current.valueOf() > moment().valueOf();
    }
    // 如果有开始时间，则不能小于开始时间且大于当前时间
    return (
      current.valueOf() < start_date.valueOf() ||
      current.valueOf() > moment().valueOf()
    );
  }
  
  return (
    <Form className="resume-form baseinfo-form-content" onSubmit={handleSubmit}>
        {!isUpload && <div className="form-header">
          {viewStatus === 2 ? "添加工作经历" : ""}
          {viewStatus === 1 ? "编辑工作经历" : ""}
        </div>}
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="公司名称">
              {getFieldDecorator("company", {
                initialValue: resume.company,
                rules: [
                  { required: true, message: "请输入公司名称" },
                ]
              })(
                <Input
                  placeholder="请输入公司名称"
                  size={size}
                />
              )}
            </FormItem>
          </Col>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="行业">
              {getFieldDecorator("industry", {
                initialValue: resume.industry ? resume.industry : undefined,
                // rules: [
                //   { required: true, message: "请选择行业" },
                // ]
              })(
                <Select placeholder="请选择行业" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.job.industry && options.job.industry.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="部门名称">
              {getFieldDecorator("deparment", {
                initialValue: resume.deparment,
                rules: [
                ]
              })(
                <Input
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入部门名称"
                  size={size}
                />
              )}
            </FormItem>
          </Col>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="职位名称">
              {getFieldDecorator("position", {
                initialValue: resume.position,
                rules: [{ required: true, message: "请输入职位名称" }]
              })(
                <Input
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入职位名称"
                  size={size}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className="resume-form-col">
          <FormItem className="form-item" label="工作类型">
              {getFieldDecorator("work_type", {
                initialValue: resume.work_type ? resume.work_type : undefined,
                rules: [{ required: false, message: "请选择工作类型" }]
              })(
                <Select placeholder="请选择工作类型" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.job.work_type && options.job.work_type.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
          <Col  className="resume-form-col special-date-col">
            <FormItem className="form-item time-item" label="在职时间">
              {getFieldDecorator("start_date", {
                initialValue: resume.start_date ? moment(resume.start_date, "YYYY-MM") : undefined,
                rules: [{ required: true, message: "请选择入职时间" }]
              })(
                <MonthPicker size={size}  format="YYYY-MM" placeholder="入职时间" 
                  disabledDate={disabledStartDate}
                  getPopupContainer={triggerNode => triggerNode.parentElement}/>
              )}
            </FormItem>
            <FormItem className="form-item font-item" label="">
              {getFieldDecorator("nothing", {
              })(
                <div>至</div>
              )}
            </FormItem>
            <FormItem className="form-item leave-item" label="">
              {getFieldDecorator("end_date", {
                initialValue: resume.end_date && resume.end_date !== "0000-00" && resume.end_date !== "至今" ? moment(resume.end_date, "YYYY-MM") : undefined,
                // rules: [{ required: true, message: "请选择离职时间" }]
              })(
                <MonthPicker size={size}  format="YYYY-MM" placeholder="离职时间" 
                  disabledDate={disabledEndDate}
                  getPopupContainer={triggerNode => triggerNode.parentElement}/>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <FormItem className="form-item" label="工作描述">
              {getFieldDecorator("work_desc", {
                initialValue: resume.work_desc,
                rules: [{ required: false, message: "请输入工作描述" }]
              })(
                <Input.TextArea rows={6}
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入工作描述"
                />
              )}
            </FormItem>
        </Row>
        {isUpload && !isLast &&
          <Row className="operate-row clear">
            <span onClick={delOperate} className="right delete-item"><img className="delete-img" src={require("assets/imgs/resume/icon-delete.png")}/>删除此条信息</span>
          </Row>
        }
        {!isUpload && 
          <Row className="operate-row clear">
            <Button className="save-btn" type="primary" size={size} htmlType="submit">保存</Button>
            <Button className="cancel-btn" size={size} onClick={overOperate}>取消</Button>
          </Row>
        }
    </Form>
  );
})

export const WorkFormWrapper = Form.create({})(WorkForm); 

export function WorkView(props) {
  const { commonResume, resume, options, resumeAction, index } = props;
  const [hover, setHover] = useState(false);
  const [viewStatus, setViewStatus] = useState(3); // 1编辑2添加3视图

  const changeView = (viewStatus) => {
    if (viewStatus === 1) {
      setHover(false);
    }
    setViewStatus(viewStatus);
  }
  useEffect(() => {
    setViewStatus(resume.isNew ? 2 : 3);
  }, [resume.isNew])
  const editOperate = useOperateView(commonResume, true, () => changeView(1));
  const delOperate = useOperateView(commonResume, true, () => deleteItem());
  const overOperate = useOperateView(commonResume, false, () => resumeAction.removeView(index));
  const cancelOperate = useOperateView(commonResume, false);

  const deleteItem = () => {
    Modal.confirm({
      title: '确定要删除该工作经历吗?',
      okText: '确定',
      cancelText: "取消",
      onOk() {
        resumeAction.delResume({
          obj_id: resume.id,
          type: 1
        }).then(res => {
          if (res.code === 0) {
            overOperate();
          } else {
            message.error(res.errorMsg);
          }
        })
      },
      onCancel() {
        cancelOperate();
      },
    })
  }
  const mouseEnter = (index) => {
    setHover(true);
  }
  const mouseLeave = (index) => {
    setHover(false);
  }
  
  let workInfos = [];
  resume.deparment && workInfos.push(resume.deparment);
  resume.position && workInfos.push(resume.position);
  resume.work_type_name && workInfos.push(resume.work_type_name);
  resume.industry_name && workInfos.push(resume.industry_name);
  return (
    <React.Fragment>
      {viewStatus === 3 && <div className="work-item-wrapper">
        <div
          className={`work-item ${hover ? 'hover' : ''}`}
          onMouseOver={() => mouseEnter()} onMouseLeave={() => mouseLeave()}>
          {hover ? <div className="operate-area">
            <span className="edit-area" onClick={editOperate}>
              <img className="edit-img" src={require("assets/imgs/resume/icon-edit.png")} />编辑
            </span>
            <span className="delete-area" onClick={delOperate}>
              <img src={require('assets/imgs/resume/icon-delete.png')} />删除
            </span>
          </div> : null}
          <div className="company">
            <span className="company-name ellipsis" title={resume.company}>{resume.company}</span>
            <span className="company-time">{resume.start_date2} - {resume.end_date2}</span>
          </div>
          <div className="work-info ellipsis" title={workInfos.join(" | ")}>{workInfos.join(" | ")}</div>
          <div className="work-desc"
            dangerouslySetInnerHTML={createMarkup(resume.work_desc)}
          ></div>
        </div>
      </div>
      }
      {(viewStatus === 1 || viewStatus === 2) && 
        <WorkFormWrapper commonResume={commonResume} resumeAction={resumeAction}
          changeView={changeView} viewStatus={viewStatus} options={options} resume={resume}/>}
    </React.Fragment>
  );
}

export default function Work(props) {
  const { user, resumeAction, options, resume} = props;
  const [ items, setItems] = useState([]);
  useEffect(() => {
    setItems(resume.jobs);
  }, [resume]);
  const addView = () => {
    if (!resume.jobs) {
      resume.jobs = [];
    }
    resume.jobs.unshift({isNew: true});
    setItems([...resume.jobs]);
  }
  const removeView = (id) => {
    if (id) {
      resume.jobs.splice(id, 1);
    } else {
      resume.jobs.shift();
    }
    setItems([...resume.jobs]);
  }
  const addOperate = useOperateView(resume, true, () => {
    if (!resume.complete_ratio){
      message.warning("还有未编辑完的项目");
      return;
    }
    addView();
  });
  return (
    <div className="form-wrapper resume-item-wrapper work-wrapper">
      <a id="work"></a>
      <div className="resume-item-header">
        <span className="title-line"></span>
        <span className="head-title">工作经历</span>
        <div className="add-area" onClick={addOperate}><img src={require('assets/imgs/resume/icon-add.png')}/>添加</div>
      </div>
      <div className="work-view-content">
        <div className="work-list">
          {
            items && items.map((item, index) => {
              return <WorkView index={index} commonResume={resume} resume={item} 
                options={options} resumeAction={{...resumeAction, removeView}}/>
            }) 
          }
        </div>
      </div>
    </div>
  );
}
