import Model from "../Model";
import * as profileService from "services/profileService";
import * as commonService from "services/commonService";
// import { message } from 'antd';

export default Model.getInstance(
  class extends Model {
    namespace = "profile";
    state = {
      commonData: {},
      profileList: {},
      profileDetail: {},
      profileLoading: false,
      profileCondition: {},
      profilePagination: {
        "pre-page": 15,
        total: 0,
      },
      jdList: {},
      jdLoading: false,
      jdPagination: {
        pages: 5,
        count: 0,
      },
      fromHomeCity: "",
      profileImg: []
    };

    actions = {
      async getConditions(param) {
        let result = await commonService.getCommonData(param);
        this.dispatch({
          type: "profile/updateCommon",
          payload: result
        });
        return result
      },
      async getProfiles(param) {
        this.dispatch({
          type: "profile/updateLoading",
          payload: true    
        });
        let result = await profileService.getProfiles(param);
        if (result.code == 0) {
          this.dispatch({
            type: "profile/updateList",
            payload: result.data     
          });
        }
        this.dispatch({
          type: "profile/updateLoading",
          payload: false
        });
      },
      async getProfileImg(param) {
        let result = await profileService.getProfileImg(param);
        if (result.code == 0) {
          this.dispatch({
            type: "profile/updateProfileImg",
            payload: result.data     
          });
        }
      },
      async sendProfile(param) {
        let result = await profileService.sendFeedback(param);
        return result;
      },
      async getProfileDetail(param) {
        let result = await profileService.getProfileDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "profile/updateDetail",
            payload: result.data     
          });
        }
        return result;
      },
      async getInternDetail(param) {
        let result = await profileService.getInternDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "profile/updateDetail",
            payload: result.data     
          });
        }
        return result;
      },
      async getJDs(param) {
        this.dispatch({
          type: "profile/updateJDLoading",
          payload: true    
        });
        let result = await profileService.getJDList(param);
        if (result.code == 0) {
          this.dispatch({
            type: "profile/updateJDList",
            payload: result.data     
          });
        }
        this.dispatch({
          type: "profile/updateJDLoading",
          payload: false
        });
      },
      async addJD(param) {
        let result = await profileService.addPosition(param);
        return result;
      },
      async operateCollection(param) {
        let result = await profileService.operateCollection(param);
        if (result.code == 0) {
          this.dispatch({
            type: "profile/updateCollected",
          })
        }
        return result;
      },
      async reserveJD(param) {
        let result = await profileService.reserve(param);
        return result;
      },
      async downloadResume(param) {
        let result = await profileService.downloadResume(param);
        return result;
      },
      async forwardResume(param) {
        let result = await profileService.forwardResume(param);
        return result;
      },
    };

    reducers = {
      updateList(state, { payload: data }) {
        return {
          ...state, 
          profileList: data, 
          profilePagination: {
            ...data.page,
          },
        };
      },
      updateJDList(state, { payload: data }) {
        return {
          ...state, 
          jdList: data, 
          jdPagination: {
            ...data.page,
          },
        };
      },
      updateCondition(state, { payload: data }) {
        return { ...state, profileCondition: data };
      },
      updateDetail(state, { payload: data }) {
        return { ...state, profileDetail: data };
      },
      updateProfileImg(state, { payload: data }) {
        return { ...state, profileImg: data };
      },
      updateJDLoading(state, { payload: data }) {
        return { ...state, jdLoading: data };
      },
      updateLoading(state, { payload: data }) {
        return { ...state, profileLoading: data };
      },
      updateCommon(state, { payload: data }) {
        return { ...state, commonData: data };
      },
      updateCollected(state, { payload: data }) {
        state.profileDetail.is_collection = !state.profileDetail.is_collection;
        if  (state.profileDetail.is_collection) {
          state.profileDetail.collection = parseInt(state.profileDetail.collection) + 1;
        } else {
          state.profileDetail.collection = parseInt(state.profileDetail.collection) - 1;
        }
        return { ...state, profileDetail: {...state.profileDetail} };
      },
    };
  }
);
