import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Tabs, message, Checkbox } from "antd";
import CustomIcon from "components/CustomIcon";
import PhoneCode from "./PhoneCode";
import AuthBg from "./AuthBg";
import userModel from "store/reducers/userModel";
import { useDispatch } from "react-redux";
import "./auth.scss";

const { TabPane } = Tabs;
const FormItem = Form.Item;
const size = "large";

function RegisterForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, modalClick } = props;
  const { location={}} = history;
  const [activeKey, setActiveKey] = useState("1");
  const [accept, setAccept] = useState(false);
  const dispatch = useDispatch();
  const tabChange = (activeKey) => {
    setActiveKey(activeKey);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    
    validateFields((err, values) => {
      if (!err) {
        if (!accept) {
          message.warning("请先同意用户协议")
          return;
        }
        console.log('Received values of form: ', values, history, userAction);
        userAction.regist(values).then(res => {
          if (res.code === 0) {
            if (userAction.fromPanel) {
              dispatch({ type: "user/closeLoginModal", payload: false });
              if (userAction.modalCallBack) {
                userAction.modalCallBack();
              } else {
                window.location.reload();
              }
            } else {
              history.push("/center");
            }
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }
  const acceptClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    window.open("/agreement");
  }

  const goLogin = () => {
    if (modalClick) {
      modalClick(1);
    } else {
      history.push("/login");
    }
  }
  
  return (
    <React.Fragment>
      <Tabs activeKey={activeKey} onChange={tabChange} className="login-tab">
        <TabPane tab="注册即派-求职端" key="1">
        </TabPane>
      </Tabs>
      <Form className="register-form verify-form" onSubmit={handleSubmit}>
        <FormItem className="form-item" label="">
          {getFieldDecorator("phone", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入手机号" },
              { pattern: new RegExp(/^1[3456789]\d{9}$/), message: "手机号码格式不正确"},
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_ph" style={{fontSize: "16px"}}/>}
              // onBlur={this.onBlurHandle}
              placeholder="请输入手机号"
              size={size}
            />
          )}
        </FormItem>
        <FormItem className="form-item" label="">
          {getFieldDecorator("password", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入密码" },
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_mima" style={{fontSize: "16px"}}/>}
              // onBlur={this.onBlurHandle}
              placeholder="请输入新密码(至少6位，数字、字母或'_')" type="password"
              size={size}
            />
          )}
        </FormItem>
        <div className="special-input">
          <FormItem className="form-item" label="">
            {getFieldDecorator("verify_code", {
              // initialValue: editClueData.company_name,
              rules: [
                { required: true, message: "请输入手机验证码" },
              ]
            })(
              <Input prefix={<CustomIcon type="icon-ic_yzm" style={{fontSize: "16px"}}/>}
                // onBlur={this.onBlurHandle}
                placeholder="请输入手机验证码"
                size={size}
                maxLength={6}
              />
            )}
          </FormItem>
          <PhoneCode className="register-get-code" formRef={props.form} userAction={userAction}/>
          <Button className="register-btn" type="primary" size={size} block htmlType="submit">免费注册</Button>
          
        </div>
      </Form>
      <div className="accept-login">
        <div className="agreement"><Checkbox onChange={(e) => setAccept(e.target.checked)}><span className="acc">接受</span><span onClick={acceptClick}>&lt;用户协议&gt;</span></Checkbox></div>
        <div className="go-login">已有账号, <span style={{cursor: "pointer"}} onClick={goLogin}><a>马上登录</a><CustomIcon type="icon-enter"/></span></div>
      </div>
    </React.Fragment>
  );
}

export const RegisterPanel = Form.create({})(RegisterForm);

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class AuthPanel extends React.Component {

  render () {
    const {history, regist} = this.props;
    const Register = Form.create({})(RegisterForm);
  
    return (
      <AuthBg history={history}>
        <Register history={history} userAction={{regist}}/>
      </AuthBg>
    );
  }
}
