import React from "react";
import { connect } from "react-redux";
import userModel from "store/reducers/userModel";
import { requestLiveList } from "services/commonService";
import "./live.scss"

@connect(
  ({ index, user }) => {
    return { ...index, user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class Live extends React.Component {

  state = {
    liveList: [],
  }

  componentDidMount() {
    requestLiveList().then(res => {
      if (res.code === 0) {
        let result = res.data;
        let resultLength = result.length;
        for (; resultLength < 8; resultLength++) {
          result[resultLength] = {};
        }
        this.setState({
          liveList: result
        })
      }
    })
  }

  goRoom = (item) => {
    if (item.watchUrl) {
      window.open("/liveroom/" + item.channelId)
    }
  }

  render() {
    const {liveList} = this.state;
    return (
      <div className="live-page">
        <div className="banner">
          <div className="title">离岸外包 共享Recruiter</div>
          <div className="tip">即用即停，灵活高效</div>
          <div className="btn">申请试用</div>
          <img src={require("assets/imgs/live/banner-img.png")} className="banner-img"/>
        </div>
        <div className="live-list">
          {
            liveList.map(item => {
              if (!item.channelId) {
                return (
                  <div className="live-item empty-item">
                    <img src={require("assets/imgs/live/empty.png")}/>
                    <div className="no-tip">暂无直播</div>
                  </div>
                )
              }
              return (
                <div className="live-item" onClick={() => this.goRoom(item)}>
                  {
                    item.watchStatus === "live" && <img className="tag" src={require("assets/imgs/live/tag-ing.png")}/>
                  }
                  {
                    item.watchStatus === "end" && <img className="tag" src={require("assets/imgs/live/tag-over.png")}/>
                  }
                  {
                    item.watchStatus === "playback" && <img className="tag" src={require("assets/imgs/live/tag-repeat.png")}/>
                  }
                  {
                    item.watchStatus === "waiting" && <img className="tag" src={require("assets/imgs/live/tag-live.png")}/>
                  }
                  <img className="live-capture-img" src={item.poster}/>
                  <div className="ellipsis title" title={item.name}>{item.name}</div>
                  <div className="time">{item.startTime}</div>
                </div>
              )
            })
          }
          
        </div>
      </div>
    );
  }
}
