import React, { Component } from "react";
import { Row, Col, Input, Modal, Button, Tabs, message } from "antd";
import CustomIcon from "components/CustomIcon";
import { is } from "immutable";
import './style.scss';
const TabPane = Tabs.TabPane;

export default class CascaderMore extends Component {
    state = {
        showDialog: false,
        panes: [{ title: "全部城市", options: [], key: "1" }],
        choose: [],
        activeKey: "1"
    };

    inputClick() {
        this.setState({ showDialog: true });
    }
    modalCancel() {
        let { value } = this.props;
        value = value ? [].concat(value) : [];
        this.setState({ choose: value }, () => {
            this.setState({ showDialog: false });
        });
    }

    componentDidMount() {
        let { options, value } = this.props;
        const { panes } = this.state;
        panes[0].options = options;
        value = value ? [].concat(value) : [];
        // 设置初始值
        this.setState({
            panes: panes,
            choose: [].concat(value)
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const nextValue = nextProps.value
            ? JSON.stringify(nextProps.value)
            : undefined;
        const chooseValue = JSON.stringify(this.state.choose);
        if (nextValue && nextValue !== chooseValue) {
            console.log(111)
            let { options } = this.props;
            let { value } = nextProps;
            const { panes } = this.state;
            panes[0].options = options;
            value = value ? [].concat(value) : [];
            // 设置初始值
            this.setState({
                panes: panes,
                choose: [].concat(value)
            });
        }
        console.log(!is(this.props.options, nextProps.options), 666)
        if (!is(this.props.options, nextProps.options)) {
            console.log(222)
            let { value, options } = nextProps;
            const { panes } = this.state;
            panes[0].options = options;
            value = value ? [].concat(value) : [];
            // 设置初始值
            this.setState({
                panes: panes,
            });
        }
    }

    arrayIndexOf(baseArray, data) {
        let index = -1;
        baseArray &&
            baseArray.forEach((item, i) => {
                if (item.id === data.id) {
                    index = i;
                }
            });
        return index;
    }

    hasChildNumber(baseList) {
        const { choose } = this.state;
        let number = 0;
        baseList.forEach(base => {
            if (this.arrayIndexOf(choose, base) !== -1) {
                number++;
            }
        });
        number = number === 0 ? "" : number;
        return number;
    }

    modalConfirm() {
        // 把选中的值给onChange
        const { choose } = this.state;
        const { onChange } = this.props;
        onChange && onChange([].concat(choose));
        this.setState({ showDialog: false });
    }

    cityClick(city) {
        const { choose, panes } = this.state;
        const { number = 5, single = false } = this.props;
        if (this.arrayIndexOf(choose, city) !== -1) {
            let index = -1;
            // 取出当前选择的位置,然后删除
            for (let i = 0, len = choose.length; i < len; i++) {
                if (choose[i].id === city.id) {
                    index = i;
                    break;
                }
            }
            choose.splice(index, 1);
        } else {
            if (city.list && city.type === 1) {
                // 如果有子选项
                let option = [{ label: city.name, data: city.list }];
                panes.push({
                    title: city.name,
                    options: option,
                    key: "2"
                });
                this.setState({ panes, activeKey: "2" });
            } else {
                if (choose && choose.length >= number && !single) {
                    message.error(`最多可以选择${number}个`);
                    return;
                }
                console.log(choose, 999, city)
                if (single) {
                    //单选
                    this.setState({ choose: [city] }, () => {
                        console.log(991, this.state.choose)
                    });
                    return;
                } else {
                    choose.push(city);
                }
            }
        }
        this.setState({ choose });
    }
    onTabClickHandel(key) {
        if (key === "1") {
            const { panes } = this.state;
            panes.splice(1, 1);
            this.setState({ panes, activeKey: "1" });
        }
    }
    tabClose(index) {
        const { choose } = this.state;
        choose.splice(index, 1);
        this.setState({ choose });
    }
    render() {
        //   type= 1是输入框 ,2初始文字，3固定文字事件
        const { placeholder, number = 5, title = "选择城市信息", type = "1" } = this.props;
        const { panes, choose } = this.state;
        return (
            <React.Fragment>
                {type === "1" &&
                    <Input
                        placeholder={placeholder}
                        value={
                            choose &&
                            choose
                                .map(item => {
                                    return item.name || "";
                                })
                                .join(",")
                        }
                        onClick={this.inputClick.bind(this)}
                    />
                }
                {type === "2" &&
                    <span className="ellipsis" style={{ cursor: 'pointer' }} onClick={this.inputClick.bind(this)}>
                        {choose &&
                            choose
                                .map(item => {
                                    return item.name || "";
                                })
                                .join(",")}
                    </span>
                }
                {type === "3" &&
                    <span className="more" onClick={this.inputClick.bind(this)}>
                        <CustomIcon type="icon-ic_more" style={{ fontSize: "9px", marginRight: "6px" }} />更多
                    </span>
                }

                <Modal
                    className="cascader-more-modal"
                    visible={this.state.showDialog}
                    title={<b>{title}</b>}
                    width={720}
                    // footer={}
                    onCancel={this.modalCancel.bind(this)}
                    onOk={this.modalConfirm.bind(this)}
                    okText="确定"
                    cancelText="取消"
                    destroyOnClose
                >
                    <div className="content">
                        {/* <div className="line-left" /> */}
                        <div className="line-right" />
                        <Row className="chooseitem">
                            最多选择 {number} 项
                            <span>
                                {choose &&
                                    choose.map((item, index) => {
                                        return (
                                            <span
                                                key={index}
                                                className="chooseitemstayle"
                                                onClick={this.tabClose.bind(this, index)}
                                            >
                                                {item.name}
                                            </span>
                                        );
                                    })}
                            </span>
                        </Row>
                        <Tabs
                            type="card"
                            className="tabbox"
                            activeKey={this.state.activeKey}
                            onTabClick={this.onTabClickHandel.bind(this)}
                        >

                            {panes &&
                                panes.length &&
                                panes.map(pane => (
                                    <TabPane tab={pane.title} key={pane.key} className="panel">
                                        {pane.options &&
                                            pane.options.map((item, i) => {
                                                return (
                                                    <div key={i}>
                                                        <Row className="itemlabel">{item.label}</Row>
                                                        <Row>
                                                            {item.data &&
                                                                item.data.map((city, j) => {
                                                                    return (
                                                                        <Col
                                                                            key={j}
                                                                            span={4}
                                                                            className="citylabel"
                                                                        >
                                                                            <span
                                                                                className={
                                                                                    this.arrayIndexOf(choose, city) !== -1
                                                                                        ? 'cityactive'
                                                                                        : null
                                                                                }
                                                                                onClick={this.cityClick.bind(
                                                                                    this,
                                                                                    city
                                                                                )}
                                                                            >
                                                                                {city.name.length > 8
                                                                                    ? city.name.substr(0, 7)
                                                                                    : city.name}
                                                                            </span>
                                                                            {city.list &&
                                                                                this.hasChildNumber(city.list) && (
                                                                                    <span className="badgestyle">
                                                                                        {this.hasChildNumber(city.list)}
                                                                                    </span>
                                                                                )}
                                                                        </Col>
                                                                    );
                                                                })}
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                    </TabPane>
                                ))}

                        </Tabs>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}
