import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, Form, Select, message, Input, Row, Col, Radio, DatePicker, Cascader, Modal, Upload } from "antd";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import CustomIcon from "components/CustomIcon";
import YearPicker from "components/YearPicker";
import PhoneCode from "pages/auth/PhoneCode";
import { verifyUserPhone } from "services/userService";
import { useOperateView } from "./resume-util";

const FormItem = Form.Item;
const { Option } = Select;
const size = "large";

const ChangePhoneForm = forwardRef((props, ref) =>  {
  const { getFieldDecorator, getFieldValue, validateFieldsAndScroll } = props.form;
  const { form, verifyCodeModal, onClose, setPhone } = props;
  useImperativeHandle(ref, () => ({
    form
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        verifyUserPhone(values).then(res => {
          if (res.code === 0) {
            setPhone(values.phone);
            onClose();
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }

  return (
      <Modal width={480} visible={verifyCodeModal}  onCancel={onClose} footer={null}
        title="更换手机号码" className="change-phone-modal" destroyOnClose>
        <div className="tip">仅更换简历的联系方式，不作修改账号</div>
        <Form className="login-phone-form verify-form change-form" onSubmit={handleSubmit}>
            <FormItem className="form-item" label="">
              {getFieldDecorator("phone", {
                // initialValue: editClueData.company_name,
                rules: [
                  { required: true, message: "请输入手机号" },
                { pattern: new RegExp(/^1[3456789]\d{9}$/), message: "手机号码格式不正确"},
                ]
              })(
                <Input prefix={<CustomIcon type="icon-ic_ph" style={{fontSize: "16px"}}/>}
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入手机号"
                  size={size}
                />
              )}
            </FormItem>
            <div className="special-input">
              <FormItem className="form-item" label="">
                {getFieldDecorator("verify_code", {
                  // initialValue: editClueData.company_name,
                  rules: [
                    { required: true, message: "请输入手机验证码" },
                  ]
                })(
                  <Input prefix={<CustomIcon type="icon-ic_yzm" style={{fontSize: "16px"}}/>}
                    // onBlur={this.onBlurHandle}
                    placeholder="请输入手机验证码"
                    size={size}
                    maxLength={6}
                  />
                )}
              </FormItem>
              <PhoneCode className="register-get-code" formRef={props.form}/>
            </div>
            <Button className="login-btn" type="primary" block htmlType="submit" size={size}>保存</Button>
        </Form>
      </Modal>
  );
});
let ChangePhone = Form.create({})(ChangePhoneForm);
export const BaseInfoForm = forwardRef((props, ref) => {
  const { getFieldDecorator, setFieldsValue, validateFieldsAndScroll } = props.form;
  const { changeView, isUpload, form, resume, viewStatus, options = { basic: {}, intent: {} }, resumeAction } = props;
  const [commonResume, setCommonResume] = useState({});
  const [verifyCodeModal, setVerifyCodeModal] = useState(false);
  const user = useSelector(state => state.user.info)

  useImperativeHandle(ref, () => ({
    form, onSubmit
  }));

  useEffect(() => {
    resume && setCommonResume(resume);
  }, [resume]);

  const overOperate = useOperateView(resume, false, () => {
    if (!resume.complete_ratio) {
      return;
    }
    changeView(3);
  });

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        values = processValues(values)
        console.log("Received values of form: ", values);
        let param = {};
        param.basic = {...values};
        if(values.reg_city.length===0){
          param.basic.reg_city_id=0
        }
        resumeAction.editResume(param).then(res => {
          if (res.code === 0) {
            overOperate();
           
          } else {
            message.error(res.errorMsg);
          }
        });
      }
    });
  };

  const onSubmit = () => {
    return new Promise((resolve, reject) => {
     validateFieldsAndScroll((err, values) => {
        if (!err) {
          values = processValues(values)
          resolve(values);
        } else {
          reject(err);
        }
      });
    });
  };

  const processValues = (values) => {
    if (values.city) values.city_id = values.city[1];
    if (values.reg_city) values.reg_city_id = values.reg_city[1];
    // if (values.work_begin_year_moment) 
    //   values.work_begin_year = values.work_begin_year_moment.format("YYYY");
    if (values.birth_date_moment) 
      values.birth_date = values.birth_date_moment.format("YYYY-MM");
    return values;
  }
  

  const modifyModal= (visible) => {
    setVerifyCodeModal(visible);
  }

  const setPhone = (phone) => {
    setFieldsValue({phone: phone})
  }

  return (
    <React.Fragment> 
      <div className="baseinfo-aller">
        <Form className="resume-form baseinfo-form-content" onSubmit={handleSubmit}>
          {!isUpload && <div className="form-header">
            {viewStatus === 2 ? "添加基本信息" : ""}
            {viewStatus === 1 ? "编辑基本信息" : ""}
          </div>}
          <Row>
            <Col className="resume-form-col">
              <FormItem className="form-item" label="真实姓名">
                {getFieldDecorator("name", {
                  initialValue: commonResume.name,
                  rules: [
                    { required: true, message: "请输入真实姓名" },
                  ]
                })(
                  <Input
                    // onBlur={this.onBlurHandle}
                    placeholder="请输入真实姓名"
                    size={size}
                  />
                )}
              </FormItem>
            </Col>
            <Col className="resume-form-col">
              <FormItem className="form-item sex-item" label=" " colon={false}>
                {getFieldDecorator("sex", {
                  initialValue: commonResume.sex ? commonResume.sex : undefined,
                  rules: [{ required: true, message: "请选择性别" }]
                })(
                  <Radio.Group size={size} className="sex-radios">
                    <Radio.Button value={1}>男</Radio.Button>
                    <Radio.Button value={2}>女</Radio.Button>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col className="resume-form-col">
              <FormItem className="form-item" label="手机号码">
                {getFieldDecorator("phone", {
                  initialValue: commonResume.phone ? commonResume.phone : user.phone,
                  rules: [
                    { required: true, message: "请输入手机号" },
                    {
                      pattern: new RegExp(/^1[3456789]\d{9}$/),
                      message: "手机号码格式不正确"
                    }
                  ]
                })(
                  <Input
                    disabled
                    placeholder="请输入手机号"
                    size={size}
                    suffix={<a className="change-phone" onClick={() => modifyModal(true)}>修改</a>}
                  />
                )}
              </FormItem>
            </Col>
            <Col className="resume-form-col">
              <FormItem className="form-item" label="联系邮箱">
                {getFieldDecorator("email", {
                  initialValue: commonResume.email,
                  rules: [{ required: false, message: "请输入联系邮箱" }]
                })(
                  <Input
                    // onBlur={this.onBlurHandle}
                    placeholder="请输入邮箱"
                    size={size}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col className="resume-form-col">
              <FormItem className="form-item" label="出生日期">
                {getFieldDecorator("birth_date_moment", {
                  initialValue: commonResume.birth_date ? moment(commonResume.birth_date, "YYYY-MM") : undefined,
                  rules: [
                    { required: true, message: "请选择出生日期" },
                    // {
                    //   pattern: new RegExp(/^1[3456789]\d{9}$/),
                    //   message: "手机号码格式不正确"
                    // }
                  ]
                })(
                  <DatePicker size={size}  format="YYYY-MM" placeholder="请选择出生日期" getPopupContainer={triggerNode => triggerNode.parentElement}/>
                )}
              </FormItem>
            </Col>
            <Col  className="resume-form-col">
              <FormItem className="form-item" label="户口">
                {getFieldDecorator("reg_city", {
                  initialValue: [commonResume.reg_province_id, commonResume.reg_city_id].filter(item=>item),
                  rules: [{ required: false, message: "请选择户口所在地" }]
                })(
                  <Cascader placeholder="请选择户口所在地" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}
                    options={options.basic ? options.basic.cities_two_level : []} 
                    fieldNames={{ label: 'name', value: 'id', children: 'list' }}
                    />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  className="resume-form-col">
              <FormItem className="form-item" label="最高学历">
                {getFieldDecorator("education", {
                  initialValue: commonResume.education ? commonResume.education : undefined,
                  rules: [
                    { required: true, message: "请选择最高学历" },
                  ]
                })(
                  <Select placeholder="请选择最高学历" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                    {
                      options.basic.education && options.basic.education.map(item => {
                        return <Option key={item.value} value={item.value}>{item.label}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col  className="resume-form-col">
              <FormItem className="form-item" label="开始工作年份">
                {getFieldDecorator("work_begin_year", {
                  // getValueFromEvent: (value) => {
                  //   console.log("getValueFromEvent", value)
                  //   return value;
                  // },
                  // trigger: "onPanelChange",
                  // validateTrigger
                  initialValue: commonResume.work_begin_year ? commonResume.work_begin_year + "" : undefined,
                  rules: [{ required: true, message: "请选择开始工作年份" }]
                })(
                  // <YearPicker size={size} placeholder="请选择开始工作年份"/>
                  <Select getPopupContainer={triggerNode => triggerNode.parentElement}
                    // onBlur={this.onBlurHandle}
                    placeholder="请选择开始工作年份"
                    size={size}
                  >
                    {
                      options.basic.work_begin_year && options.basic.work_begin_year.map(item => {
                        return <Option key={item.value} value={item.value}>{item.label}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  className="resume-form-col">
              <FormItem className="form-item" label="求职状态">
                {getFieldDecorator("status", {
                  initialValue: commonResume.status ? commonResume.status : undefined,
                  rules: [
                    { required: false, message: "请选择求职状态" },
                  ]
                })(
                  <Select getPopupContainer={triggerNode => triggerNode.parentElement}
                    // onBlur={this.onBlurHandle}
                    placeholder="请选择求职状态"
                    size={size}
                  >
                    {
                      options.basic.status && options.basic.status.map(item => {
                        return <Option key={item.value} value={item.value}>{item.label}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col  className="resume-form-col">
              <FormItem className="form-item" label="婚姻状况">
                {getFieldDecorator("marry_status", {
                  initialValue: commonResume.marry_status ? commonResume.marry_status : undefined,
                  rules: [{ required: false, message: "请选择婚姻状况" }]
                })(
                  <Select getPopupContainer={triggerNode => triggerNode.parentElement}
                    // onBlur={this.onBlurHandle}
                    placeholder="请选择婚姻状况"
                    size={size}
                  >
                    {
                      options.basic.marry_status && options.basic.marry_status.map(item => {
                        return <Option key={item.value} value={item.value}>{item.label}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  className="resume-form-col">
              <FormItem className="form-item" label="居住地">
                {getFieldDecorator("city", {
                  initialValue: [commonResume.province_id, commonResume.city_id].filter(item=>item),
                  rules: [
                    { required: true, message: "请选择居住地" },
                  ]
                })(
                  <Cascader placeholder="请选择户口所在地" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}
                    options={options.basic ? options.basic.cities_two_level : []} 
                    fieldNames={{ label: 'name', value: 'id', children: 'list' }}
                    />
                )}
              </FormItem>
            </Col>
            {/* <Col  className="resume-form-col">
              <FormItem className="form-item" label="目前年收入">
                {getFieldDecorator("current_year_salary", {
                  initialValue: commonResume.current_year_salary ? commonResume.current_year_salary : undefined,
                  rules: [{ required: true, message: "请选择目前年收入" }]
                })(
                  <Select getPopupContainer={triggerNode => triggerNode.parentElement}
                    // onBlur={this.onBlurHandle}
                    placeholder="请选择目前年收入"
                    size={size}
                  >
                    {
                      options.basic.current_year_salary && options.basic.current_year_salary.map(item => {
                        return <Option key={item.value} value={item.value}>{item.label}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col> */}
          </Row>
          {!isUpload && 
            <Row className="operate-row clear">
              <Button className="save-btn" type="primary" size={size} htmlType="submit">保存</Button>
              <Button className="cancel-btn" size={size} onClick={overOperate}>取消</Button>
            </Row>
          }
        </Form>
      </div>
      <ChangePhone verifyCodeModal={verifyCodeModal} 
        setPhone={setPhone} onClose={() => modifyModal(false)}/>
    </React.Fragment>
  );
})

export function BaseInfoView(props) {
  const { changeView, resume, resumeAction } = props;
  const dispatch = useDispatch();
  const editOperate = useOperateView(resume, true, () => changeView(1));

  let baseinfos = [];
  resume.sex === 1 && baseinfos.push("男");
  resume.sex === 2 && baseinfos.push("女");
  resume.age && baseinfos.push(resume.age + "岁");
  resume.marry_status_name && baseinfos.push(resume.marry_status_name);
  resume.education_name && baseinfos.push(resume.education_name);
  resume.city_name && baseinfos.push("现居住：" + resume.city_name);

  const uploadProps = {
    name: 'attachment',
    action: '/cpc/v1/common/upload',
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        let res = info.file.response;
        if (res.code === 0) {
          dispatch({type: "resumeModel/updateResumeItem", payload: {
            field: "avatar",
            value: res.data.url,
          }})
          resumeAction.editResume({basic: {avatar: res.data.url}}).then(edres => {
            if (edres.code === 0) {
            } else {
              message.error(res.errorMsg);
            }
          });
        } else {
          message.error(res.msg);
        }
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name}上传失败`);
      }
    },
  };
  let intents = resume.intents && resume.intents.length !== 0 ? resume.intents[0] : {};
  return (
    <div className="baseinfo-view-content">
      <div className="avatar-area"><Upload {...uploadProps}><img src={resume.avatar ? resume.avatar : require("assets/imgs/default.png")}/></Upload></div>
      <div className="baseinfo">
        <div className="name ellipsis" title={resume.name}>{resume.name}</div>
        <div className="detail">{baseinfos.join(" | ")}</div>
        <div>
          {resume.phone && <span className="phone"><CustomIcon type="icon-ic_ph2"/>{resume.phone}</span>}
          {resume.email && <span className="email"><CustomIcon type="icon-ic_youxiang"/>{resume.email}</span>}
        </div>
        <div className="resume-status">
          <div className="status-item">{resume.status_name}</div>
          {(intents && intents.outsourcing && intents.outsourcing !== 1) ? <div className="status-item">接受外包</div> : null}
          
        </div>
      </div>
      <div className="edit-area" onClick={editOperate}>
        <img className="edit-img" src={require("assets/imgs/resume/icon-edit.png")}/>编辑
      </div>
    </div>
  );
}

export const BaseInfoFormWrapper = Form.create({})(BaseInfoForm);

export default function BaseInfo(props) {
  const { resumeAction, resume, options, user } = props;
  const [viewStatus, setViewStatus] = useState(3); // 1编辑 2添加 3视图
  
  const changeView = (viewStatus) => {
    setViewStatus(viewStatus)
  }

  const addOperate = useOperateView(resume, true, () => changeView(2));

  useEffect(() => {
    console.log(123)
    if (resume.complete_ratio && resume.complete_ratio !== "0%") {
      setViewStatus(3);
    } else {
      addOperate();
    }
  }, [resume.complete_ratio]);

  return (
    <div className="form-wrapper resume-item-wrapper baseinfo-wrapper">
      <a id="baseinfo"></a>
      {(viewStatus === 1 || viewStatus === 2) && 
        <BaseInfoFormWrapper changeView={changeView} viewStatus={viewStatus} resume={resume} options={options} resumeAction={resumeAction}/>}
      {viewStatus === 3 && <BaseInfoView changeView={changeView} resume={resume} resumeAction={resumeAction}/>}
    </div>
  );
}
