import React, { Component } from "react";
import { DatePicker } from "antd";

export default class YearPicker extends Component {
  state = {
    isopen: false,
    time: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({      
        time: this.props.value
      })  
    }
  }

  handlePanelChange = (value) => {    
    console.log(">>>>>", value)    
    const {...props} = this.props;

    this.setState({      
      time: value,      
      isopen: false    
    })  
    props && props.onPanelChange(value);
  } 
 
  handleOpenChange = (status) => {    
    // console.log(status)    
    if(status){      
      this.setState({isopen: true})    
    } else {      
      this.setState({isopen: false})    
    }  
  }  
  
  clearValue = (e) => {   
    const {...props} = this.props;
    this.setState({      
      time: null    
    })  
    props && props.onPanelChange(null);
  }

  render() {
    const { isopen, time } = this.state;
    const {
      ...props
    } = this.props;
    
    return (
      <DatePicker
        getPopupContainer={triggerNode => triggerNode.parentElement}
        {...props}
        value={time}
        open={isopen}
        mode="year"
        format="YYYY"
        onOpenChange={this.handleOpenChange}              
        onPanelChange={this.handlePanelChange}              
        onChange={this.clearValue}      
        />        
    );
  }
}

