import Model from "../Model";
import * as resumeService from "services/resumeService";
// import { message } from 'antd';

export default Model.getInstance(
  class extends Model {
    namespace = "resumeModel";
    state = {
      resume: {},
    };

    actions = {
      async getResumeDetail(param) {
        this.dispatch({
          type: "resumeModel/updateLoading",
          payload: true    
        });
        let result = await resumeService.requestResumeDetail(param);
        if (result.code == 0) {
          this.dispatch({
            type: "resumeModel/updateResume",
            payload: result.data  
          });
        }
        this.dispatch({
          type: "resumeModel/updateLoading",
          payload: false
        });
        return result;
      },
      async editResume(param) {
        // this.dispatch({
        //   type: "resumeModel/updateLoading",
        //   payload: true    
        // });
        let result = await resumeService.submitResume(param);
        if (result.code == 0) {
          this.dispatch({
            type: "resumeModel/updateResume",
            payload: result.data  
          });
        }
        // this.dispatch({
        //   type: "resumeModel/updateLoading",
        //   payload: false
        // });
        return result;
      },
      async delResume(param) {
        let result = await resumeService.deleteResume(param);
        if (result.code == 0) {
          this.dispatch({
            type: "resumeModel/updateResume",
            payload: result.data  
          });
        }
        return result;
      },
      async modifyResumeSetting(param) {
        let result = await resumeService.submitResumeSetting(param);
        if (result.code == 0) {
          // this.dispatch({
          //   type: "resumeModel/updateResume",
          //   payload: result.data  
          // });
        }
        return result;
      },
    };

    reducers = {
      updateResumeIsEdit(state, { payload: data }) {
        return {
          ...state, 
          resume: {...state.resume, resumeIsEdit: data}, 
        };
      },
      updateResumeItem(state, { payload: data }) {
        state.resume[data.field] = data.value;
        return {
          ...state, 
          resume: {...state.resume}, 
        };
      },
      updateResume(state, { payload: data }) {
        return {
          ...state, 
          resume: {...data}, 
        };
      },
      updateCondition(state, { payload: data }) {
        return {
          ...state, 
          businessCondition: {...data}, 
        };
      },
    };
  }
);
