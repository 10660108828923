import Model from "../Model";
import * as systemMessageS from "services/systemMessageS";

export default Model.getInstance(
  class extends Model {
    namespace = "SystemMessageModal";
    state = {
      statusParam: {
        page: 1,
        pages: 10
      },
      dataMessage: {}
    };

    actions = {
      async getMessageList() {
        let result = await systemMessageS.systemMessage(
          this.getState().SystemMessageModal.statusParam
        );
        if (result.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "dataMessage", value: result.data }
          });
        }
        return result;
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
