import React, { Component } from "react";
import { Select, Cascader } from "antd";

class MultiCascade extends Component {
  cascadeOnMouse = false; // 级联是否聚焦
  selectOnFocus = false; // select是否聚焦
  cascaderRef = React.createRef();

  names = ["province", "city", "region"];

  state = {
    popupVisible: false // 级联浮层显隐
  };

  onSelectFocus = () => {
    this.selectOnFocus = true;
    this.setState({ popupVisible: true });
  };

  onSelectBlur = () => {
    this.selectOnFocus = false;
    this.setState({ popupVisible: this.cascadeOnMouse });
  };

  onCascadeMouseEnter = () => {
    this.cascadeOnMouse = true;
  };

  onCascadeMouseLeave = () => {
    this.cascadeOnMouse = false;
    this.setState({
      popupVisible: this.selectOnFocus
    });
  };

  convertValues = value => {
    const names = this.names;
    value = value || [];
    
    return value.map(item => {
      const keys = [];
      const labels = [];
      for (const name of names) {
        const key = item[name];
        const label = item[`${name}_label`];
        key ? keys.push(key) : !1;
        label ? labels.push(label) : !1;
      }
      return {
        label: labels[labels.length - 1],
        key: keys.join("-")
      };
    });
  };

  same = (res, currentValue) => {
    const names = this.names;
    let index = 0;
    for (const item of currentValue) {
      console.log("index", index++);
      const same = [];
      names.map(key => {
        const curKeyValue = item[key] ? parseInt(item[key]) : undefined;
        console.log("values", res[key], curKeyValue);
        same.push(res[key] === curKeyValue);
      });
      console.log("same", same);
      if (!same.includes(false)) return true;
    }
    return false;
  };

  onCascaderChange = (value, selectedOptions) => {
    const { fieldNames = {}, onChange, selsize, limit } = this.props;
    const currentValue = this.props.value || [];
    const res = {},
      names = this.names;
    if (limit && currentValue.length >= limit) {
      return;
    }
    selectedOptions.map((item, index) => {
      res[names[index]] = item[fieldNames["value"] || "value"];
      res[`${names[index]}_label`] = item[fieldNames["label"] || "label"];
    });

    if (!this.same(res, currentValue) && value.length > (selsize || 1)) {
      onChange([...currentValue, res]);
    }
  };

  onSelectChange = value => {
    const { onChange } = this.props;
    value = value.map(item => {
      const [province, city, region] = item.key.split("-"),
        [province_label, city_label, region_label] = item.label.split("-");
      return {
        province,
        province_label,
        city,
        city_label,
        region,
        region_label
      };
    });
    onChange(value);
  };

  onSelectSearch = value => {
    if (this.props.showSearch) {
      this.cascaderRef.current.setState({ inputValue: value });
    }
  };

  render() {
    const { popupVisible } = this.state;
    const {
      allowClear,
      value,
      placeholder,
      style,
      size,
      onChange,
      ...props
    } = this.props;
    const convertValue = this.convertValues(value);
    console.log(convertValue, "intenconvertValuet")
    return (
      <div className="cascadeMultiple">
        <Select
          mode="multiple"
          open={false}
          labelInValue
          placeholder={placeholder}
          onFocus={this.onSelectFocus}
          onBlur={this.onSelectBlur}
          value={convertValue}
          onSearch={this.onSelectSearch}
          onChange={this.onSelectChange}
          style={style}
          size={size}
        />
        <div
          onMouseEnter={this.onCascadeMouseEnter}
          onMouseLeave={this.onCascadeMouseLeave}
        >
          <Cascader
            ref={this.cascaderRef}
            className="cascade"
            changeOnSelect
            size={size}
            popupVisible={popupVisible}
            onChange={this.onCascaderChange}
            {...props}
            expandTrigger={props.expandTrigger||"hover"}
          />
        </div>
      </div>
    );
  }
}

export default MultiCascade;
