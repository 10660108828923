import xhr from "./index";

//职位详情
function positionDetail(param) {
  return xhr({ url: "/v4_0/jd/info", body: param, method: "get" });
}
//职位编辑
function positionEdit(param) {
  return xhr({ url: "/v4_0/jd/edit", body: param, method: "post" });
}
//职位候选人列表
function positionProfilesList(param) {
  return xhr({ url: "/v4_0/jd/profiles", body: param, method: "get" });
}
//服务进展
function serveProgress(param) {
  return xhr({ url: "/v4_0/jd/logs", body: param, method: "get" });
}
//服务暂停或取消
function isServeStop(param) {
  return xhr({ url: "/v4_0/jd/pause", body: param, method: "post" });
}
//确认报价
function confirmQuote(param) {
  return xhr({ url: "/v4_0/jd/confirmQuote", body: param, method: "post" });
}
export {
  positionDetail,
  positionEdit,
  positionProfilesList,
  serveProgress,
  isServeStop,
  confirmQuote
};
