import React, { useState, useEffect } from 'react';
import CustomIcon from "components/CustomIcon";
import { Popover, Message, Button } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import * as positionService from "services/positionService";
import './style.scss';


export default function Head(props = {}) {
    const dispatch = useDispatch();
    console.log(789, props)
    const user = useSelector(state => state.user)
    const { info = {}, history } = props;
    const [suspendVisible, setSuspend] = useState(info.jd_qrcode)
    const [is_collect, setCollect] = useState()
    const [is_applied, setApplied] = useState();
    useEffect(() => {
        setCollect(info.is_collect);
        setApplied(info.is_applied);
    }, [info.is_collect, info.is_applied])
    async function onCollect() {
        let fetchName = {
            0: "positionCollect",
            1: "positionCollectCancel",
        }
        let res = await positionService[fetchName[is_collect]]({ jd_id: info.id });
        if (res.code === 0) {
            setCollect(is_collect ? 0 : 1);
        } else {
            if (res.code == 5112) {
                open();
            } else {
                Message.error(res.transformError || res.msg || res.data);
            }
            return;
        }
    }
    async function onApplied() {
        console.log(66, user)
        if (!user.info.id) {
            open();
            return;
        }
        if (!user.info.profile_id) {
            Message.warning("您没有简历，请去完善简历");
            return;
        }
        let res = await positionService.positionApplied({ jd_ids: [info.id], profile_id: user.info.profile_id });
        if (res.code === 0) {
            console.log(props)
            setApplied(is_applied ? 0 : 1);
            Message.success("简历投递成功!")
        } else {
            if (res.code == 5112) {
                open();
            } else {
                Message.error(res.transformError || res.msg || res.data);
            }
            return;
        }
    }
    function open() {
        dispatch({
            type: "user/openLoginModal", payload: {
                visible: true,
            }
        });
    }
    function perfect() {
        if (!user.info.id) {
            open();
            return;
        }
        history.push("/resume")
    }
    function getQR() { setSuspend(info.jd_qrcode) }
    const imgContent = (
        <div className="img-content" style={{ width: "116px", height: "110px" }}>
            {
                suspendVisible && <img style={{ width: "100%", height: "100%" }} src={suspendVisible} />
            }
        </div>
    )
    return (
        <div className="head">
            <div className="container-g">
                <div className="box">
                    <div className="row">
                        <div className="left-side">
                            <div className="time">
                                更新于：{info.update_time_str}
                            </div>
                            <p className="position ellipsis" title={info.name}>{info.name}</p>
                            <p className="salary">{info.original_salary}</p>
                            <p className="info">{info.joint}</p>
                        </div>
                        <div className="right-side">
                            <div className="handle">
                                <span className={`collect-btn ${is_collect ? 'is-collect' : ''}`} onClick={onCollect}>
                                    <img style={{ width: "17px", height: "16px", marginRight: "5px", verticalAlign: "sub" }} src={require(`assets/imgs/icon/${is_collect ? 'collected' : 'collect'}.png`)} />
                                    {is_collect ? "已" : ""}收藏</span>   
                                    {
                                        info.id&&
                                         <Button onClick={onApplied} className="apply" type="primary" disabled={!!is_applied || info.status != 1||info.is_end_time==1}>
                                {info.status !== 1?"职位已下线":!!is_applied ? "已投递":((info.status === 1&&info.is_end_time === 0)) ? "申请投递":"职位已下线"}
                                </Button>
                                    }
                               {!info.id&&
                                <Button onClick={onApplied} className="apply" type="primary" disabled={!!is_applied || info.status != 1||info.is_end_time==1}>
                                    加载中
                                </Button>
                               }
                            </div>
                            <div>
                                <span className="perfect" onClick={ ()=>perfect() } >
                                    <CustomIcon className="d1" type={"icon-ic_jl"} style={{ fontSize: "15px" }} />
                                    <CustomIcon className="d2" type="icon-ic_jl-copy" style={{ fontSize: "15px" }} />
                                    <span>完善简历</span>
                                </span>
                                {
                                   ( info.status === 1&&info.is_end_time === 0) &&
                                    <Popover content={imgContent} placement="right">
                                        <span onMouseEnter={getQR} onMouseLeave={() => { setSuspend("") }}> <CustomIcon type={!!suspendVisible ? "icon-ic_xcx-copy" : "icon-ic_xcx"} style={{ fontSize: "15px" }} /><span style={{ color: suspendVisible ? "#0E79FB" : "#999999" }}>微信扫码分享</span></span>
                                    </Popover>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
