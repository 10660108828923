import React from "react";
import { urlEncode } from "utils/url";
import { parseQueryString } from "utils/url";
import { Route } from "react-router-dom";
import { matchRoutes } from "react-router-config";
import createStaticRoutes from "utils/createStaticRoutes";
const proxies = require(process.env.PACKAGE_JSON).proxy;
const _ = require("underscore");
const history = process.env.BROWSER ? require("utils/history").default : null;
const root = process.env.BROWSER ? require("../root") : null;

const xhr = ({ url, body = null, method = "get" }, req) => {
  function transformError(response) {
    let message = "系统异常，请联系管理员";
    if (!_.isEmpty(response.msg)) {
      message = response.msg;
    }
    if (!_.isEmpty(response.data)) {
      if (_.isArray(response.data)) {
        message = _.values(response.data)[0].message;
      } else if (_.isObject(response.data)) {
        message = _.values(response.data)[0];
      } else if (_.isString(response.data)) {
        message = response.data;
      }
    }
    return message;
  }

  function parseRequest(response) {
    response.errorMsg = ""; //
    if (response.code == 0 || (response.code >= 200 && response.code < 300)) {
      return response;
    } else if (response.code == 404) {
      // 这里抛出错误方便服务器端也能处理
      throw response;
    } else if (response.code == 500) {
      throw response;
    } else {
      response.errorMsg = transformError(response);
      return response;
    }
  }

  let whiteList = [{path: "/positionDetail/:id"}, {path: "/position"}]

  /**
   * handle unlogin case.
   * pop up login dialog when any interface return code 5112
   * except url with 'state' param, it's three part login process
   * @param {*} response
   */
  function handle5112(response) {
    if (process.env.BROWSER) {
      // const { search } = history.location;
      // const urlParams = parseQueryString(search);
      let code = parseInt(response.code);
      if (code === 101 || code === 5112) {
        root.store.dispatch({ type: "user/setUserInfo", payload: {} });
        localStorage.removeItem("user");
        //职位详情不跳首页
        let noWhiteListMatch = matchRoutes(whiteList, window.location.pathname).length === 0;
        if (noWhiteListMatch) history.replace("/");
      }
    }
    return response;
  }

  // function checkStatus(response) {
  //   if (response.status >= 200 && response.status < 300) {
  //     return response;
  //   } else {
  //     let error = new Error(response.statusText);
  //     error.response = response;
  //     throw error;
  //   }
  // }

  function parseJSON(response) {
    return response.json();
  }

  function log(response) {
    return response;
  }

  let headers = {
    "Content-Type": "application/json",
    Accept: "*/*"
  }

  if (req) {
    headers["Cookie"] = req.headers.cookie;
  }

  let param = {
    method: method,
    headers: headers
  };
  if (body) {
    ["post", "POST", "put", "PUT"].indexOf(method) !== -1
      ? (param.body = JSON.stringify(body))
      : (url = `${url}?${urlEncode(body)}`);
  }

  param.credentials = "include";
  return (
    fetch(url, param)
      // .then(checkStatus)
      .then(parseJSON)
      .then(parseRequest)
      .then(handle5112)
      .then(log)
      .catch(response => {
        console.error("error", response);
        if (response.code === 404) {
          // 重定向404
          console.log(response);
        } else if (response.code === 500) {
          // 重定向500
          console.log(response);
        }
      })
      .then(response => response || {})
  );
};

export function serverXhr(xhr) {
  return ({ url, body, method }, req) => {
    url =
      process.env.BUILD_TARGET === "server"
        ? findHost(proxies, url) + url
        : url;
    return xhr({ url, body, method }, req);
  };
}

export function findHost(proxies, url) {
  for (const path of Object.keys(proxies)) {
    if (new RegExp(path).test(url)) {
      return proxies[path].target;
    }
  }
  return "";
}

export default serverXhr(xhr);
