
import React, { useState, useEffect } from 'react';
import CustomIcon from "components/CustomIcon";
import { Button, Modal, Icon, Message, Popconfirm } from 'antd';
import './style.scss';
import { isAssociative } from 'immutable';

const desSpan = {
    0: "未反馈", 1: "不合适", 2: "已入职", 3: "已失效", 4: "合适"
}
export default function PositionCard(props) {
    const { item = {}, type } = props;
    return (
        <div className="position-card-container" {...props}
        >
            <div className="main">
                <div className="show">
                    <div className="left-side">
                        <p className="name ellipsis" title={item.name}>{item.name}</p>
                        <div className="base">
                            <span className="salary ellipsis">{item.original_salary}</span>
                            <span className="info ellipsis" title={item.joint}>{item.joint}</span>
                        </div>
                        {
                            (type != "2" && item.benefit && item.benefit.length > 0) &&
                            <div className="welfare ellipsis">
                                {item.benefit.map((itemWelfare, index) => {
                                    return (<span title={""} key={index} className="label">{itemWelfare.name}</span>)
                                })}
                            </div>
                        }
                    </div>
                    <div className="right-side" onClick={e => {
                        e.stopPropagation();
                        e.preventDefault()
                    }}>
                        {type != "2" && item.publish_time_str && <div className="time" style={{ marginBottom: type === "3" ? "40px" : "15px" }}>发布时间：{item.publish_time_str}</div>}
                        {type == "2" && item.add_time && <div className="time" style={{ marginBottom: "15px" }}>申请时间：{item.add_time}</div>}
                        {type === "2" &&
                            <div className="record" >
                                <span className="label active">已投递</span>
                                <span className={`label ${item.is_viewed === 1 && 'active'}`}>{item.is_viewed === 1 ? "已查看" : "未查看"}</span>
                                <span className={`label ${(item.status !== 0) && 'active'}`}>{desSpan[item.status]}</span>
                            </div>
                        }
                        {type === "3" &&
                            <div className="collect-handle">
                                {(item.status === 1&&item.is_end_time===0) &&
                                    <Button onClick={() => props.onHandleCard(1, item)} disabled={item.is_applied === 1} className="deliver">{item.is_applied === 1 ? '已投递' : '投递简历'}</Button>
                                }
                                <Button onClick={() => props.onHandleCard(2, item)}>取消收藏</Button>
                            </div>
                        }
                    </div>
                </div>
                {(type === "2" && item.status === 1) && <div className="reason" title={item.unfit_reason}>
                    <div className="ellipsis">不合适原因：{item.unfit_reason}</div>
                </div>}
            </div>
        </div>
    )
}