import React from "react";
import { connect } from "react-redux";
import "./live-room.scss"

@connect(
  ({ index, user }) => {
    return { ...index, user: user };
  },
  {
  }
)
export default class LiveRoom extends React.Component {

  state = {
    liveid: "",
  }

  componentDidMount() {
    window.document.body.style.overflow = "hidden"
  }

  render() {
    const {match} = this.props
    return (
      <iframe style={{width: "100%", height: "100%", border: "none"}} src={`https://live.polyv.cn/watch/${match.params.id}`}></iframe>
    );
  }
}
