import React, { useState, useRef, useEffect } from "react";
import { Menu, Popover, Dropdown } from "antd";
import { Link } from "react-router-dom";
import CustomIcon from "components/CustomIcon";

export default function BasicHeader(props) {
  const { user, location, history, matchRoute, userAction } = props;
  const [isLogin, setIsLogin] = useState(false);
  const [taskPopVisible, setTaskVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [isIntroduce, setIsIntroduce] = useState(false);
  const taskContainer = useRef(null);
  const loginOut = () => {
    userAction.logout().then(res => {});
  };
  const taskContent = (
    <div>
      <div className="gee-coin">
        <div className="title bold">每日任务</div>
        <div className="coin">
          余额：<span>{user.info.point}</span>
        </div>
      </div>
      <div>
        <img src={require("assets/imgs/home/task-guide.png")} />
      </div>
    </div>
  );
  const centerMenu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={loginOut}>
          退出
        </a>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    matchRoute.firstLevel && setSelectedKeys([matchRoute.firstLevel]);
    if (
      matchRoute.path &&
      matchRoute.path.indexOf("/infointroduction") !== -1
    ) {
      setIsIntroduce(true);
    } else {
      setIsIntroduce(false);
    }
    setIsLogin(!!user.info.id);
  }, [user, matchRoute, location]);
  return (
    <div className="layout-header-wrapper">
      <div className="layout-header">
        <div className="logo" onClick={() => history.push("/")}>
          <img src={require("assets/imgs/home/head-logo.png")} />
        </div>
        {!isLogin && !isIntroduce && (
          <React.Fragment>
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={selectedKeys}
              className="nav-menu no-login-nav-menu"
            >
              <Menu.Item key="1">
                <Link to={`/`}>首页</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/position`}>职位</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={`/live`}>空中宣讲会</Link>
              </Menu.Item>
              {/* <Menu.Item key="3">
                <Link to={`/resume`}>简历</Link>
              </Menu.Item> */}
            </Menu>
            <div className="site-changer site-right">
              <div className="site">
                <a target="_blank" href="https://h.geetemp.com">
                  我是猎头
                </a>
              </div>

              <div className="hr-site site">
                <a target="_blank" href="https://www.geetemp.com">
                  我是招聘方<span className="site-tip">我要招人</span>
                </a>
              </div>
              <a className="auth" onClick={() => history.push("/login")} rel="nofollow">
                登录
              </a>

              <a className="auth" onClick={() => history.push("/register")} rel="nofollow">
                注册
              </a>
            </div>
          </React.Fragment>
        )}
        <div className="header-right site-right">
          {isIntroduce ? (
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={selectedKeys}
              className="nav-menu"
            >
              <Menu.Item key="3">
                <Link to={`/infointroduction`}>即派简介</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={`/infointroduction/dynamic`}>即派动态</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to={`/infointroduction/join`}>加入即派</Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to={`/infointroduction/question`}>常见问题</Link>
              </Menu.Item>
            </Menu>
          ) : (
            <React.Fragment>
              {isLogin && (
                <React.Fragment>
                  <Menu
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={selectedKeys}
                    className="nav-menu"
                  >
                    <Menu.Item key="1">
                      <Link to={`/`}>首页</Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <Link to={`/position`}>职位</Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                      <Link to={`/resume`}>简历</Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                      <Link to={`/live`}>空中宣讲会</Link>
                    </Menu.Item>
                  </Menu>

                  <div className="my-center">
                    {/* <div className="task" ref={taskContainer}>
                      <Popover
                        onVisibleChange={visible => setTaskVisible(visible)}
                        title={null}
                        overlayClassName="task-popover"
                        content={taskContent}
                        getPopupContainer={() => taskContainer.current}
                      >
                        <CustomIcon
                          type={
                            taskPopVisible ? "icon-ic_qd-hover" : "icon-ic_qd"
                          }
                          className="icon-task"
                        />
                        <span className={taskPopVisible ? "hover" : ""}>
                          领派币
                        </span>
                      </Popover>
                    </div> */}

                    <div className="center">
                      <Dropdown overlay={centerMenu}>
                        <span className="center-inner">
                          <span>
                            你好，
                            {user.info.name}
                          </span>
                          <img
                            src={
                              user.info.avatar
                                ? user.info.avatar
                                : require("assets/imgs/default.png")
                            }
                          />
                        </span>
                      </Dropdown>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
