import React from "react";
import {  Modal, Button, Popover, Row, Col, Icon, Select } from "antd";
import * as commonService from "services/commonService";

const Option = Select.Option;

export default class ResumeSet extends React.Component {
    state = {
        is_open_resume: 1,
        profile_option: {},
    }
    componentDidMount() {
        commonService.getCommonData(["profile_option"]).then(res => {
          this.setState({
            profile_option: res.profile_option
          })
        });
    }
    render() {
        const { props } = this;
        const { profile_option } = this.state;
        const content = (
            <div style={{fontSize:"12px"}}>
                <div style={{marginBottom:"8px"}}>公开后：将有机会获得猎头和HR职位联系</div>
                <div>隐藏后：你的简历将不会被HR主动搜索到</div>
            </div>
        )
        return (
            <Modal
                width="480px"
                title={<b>设置</b>}
                visible={props.visible}
                onCancel={props.setAction.onCancel}
                onOk={() => { props.setAction.onOk({ is_open_resume:this.state.is_open_resume }).then((res)=>{
                    props.setAction.checkUserInfo()
                }) }}
                okText="确认"
                cancelText="取消"
            >
                <div className="deliver-dialog">
                    <Row align="middle" type="flex">
                        <Col span={5}>
                            隐私设置：
                        </Col>
                        <Col span={12}>
                            <Select
                                placeholder="请选择"
                                onSelect={(e) => { this.setState({ is_open_resume: e }), console.log(e) }}
                                style={{ width: "100%" }}
                                defaultValue={this.state.is_open_resume}
                            >
                                {
                                    profile_option && profile_option.is_open_resume && profile_option.is_open_resume.map(item => {
                                        return <Option key={item.value} value={item.value} title={item.label}>{item.label}</Option>
                                    })
                                }
                            </Select>
                        </Col>
                        <Col span={2} offset={1}>
                            <Popover content={content} trigger="hover">
                                <Icon type="info-circle" />
                            </Popover>
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}




