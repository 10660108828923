import React from "react";
import { BackTop, Menu } from "antd";
import { connect } from "react-redux";
import { matchRoutes } from "react-router-config";
import Header from "./Header";
import Footer from "./Footer";
import ExFooter from "./ExFooter";
import LoginModal from "pages/auth/LoginModal";
import ResumeSet from "pages/resume/resumeSet";
import userModel from "store/reducers/userModel";
import commonModel from "store/reducers/commonModel";

import "./BasicLayout.scss";

@connect(
  ({ common, user }) => {
    return { ...common, user };
  },
  {
    ...userModel.actions,
    setMatchRoute: commonModel.actions.setMatchRoute,
  }
)
export default class BasicLayout extends React.PureComponent {
  state = {
    currentUser: {}
  }
  componentDidMount() {
    const {checkUserInfo, history, route, location, setMatchRoute} = this.props;
    const matchedRoutes = matchRoutes(route.routes, location.pathname);
    
    matchedRoutes.map(({ route, match }) => {
      setMatchRoute(route || {});
      if (!route.noRequest) {
        checkUserInfo().then(this.checkUserStatus);
      }
    });    
  }

  componentDidUpdate(prevProps) {
    const {user, route, location, checkUserInfo, setMatchRoute} = this.props;
    const matchedRoutes = matchRoutes(route.routes, location.pathname);
    if (location.pathname !== prevProps.location.pathname) {
      matchedRoutes.map(({ route, match }) => {
        setMatchRoute(route || {});
        if (!route.noRequest) {
          checkUserInfo().then(this.checkUserStatus);
        }
      });
    }
    if (user !== prevProps.user) {
      this.setState({
        currentUser: user.info
      })
    }
  }

  checkUserStatus = (res) => {
  }

  render() {
    const {
      children, user, location, history, matchRoute, logout, phoneLogin, regist, forgetPwdReset,isOpenResumeSet,okResumeSet,checkUserInfo
    } = this.props;
    console.log('uu',this.props)
    const {currentUser} = this.state;
    return (
      <div className="layout" style={{height: "100%", width: "100%"}}>
        <Header user={user} location={location} history={history} matchRoute={matchRoute} userAction={{logout}}/>
        <div className="layout-content">
          {children}
        </div>
        {!currentUser.id && <ExFooter />}
        {currentUser.id && <Footer />}
        <BackTop>
          <img src={require("assets/imgs/backtop.png")}/>
        </BackTop>
        <LoginModal visible={user.loginRegistModal} history={history} 
          userAction={{phoneLogin, regist, forgetPwdReset, modalCallBack: user.loginRegistModalCallBack, fromPanel: true}}/>
        <ResumeSet visible={user.resumeSetStatus} setAction={{onOk:okResumeSet,onCancel:isOpenResumeSet,checkUserInfo:checkUserInfo}} />
      </div>
    );
  }
}
