import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Select, message, Input, Row, Col, Radio, DatePicker, Modal, Steps, Empty } from "antd";
import {BaseInfoFormWrapper} from './baseinfo';
import {IntentFormWrapper} from './intent';
import {WorkFormWrapper} from './work';
import {ProjectFormWrapper} from './project';
import {EducationFormWrapper} from './education';
import _ from 'underscore';
import CustomizeRenderEmpty from 'components/CustomizeRenderEmpty';

const { Step } = Steps;
const FormItem = Form.Item;
const size = "large";

function createMarkup(html = "") {
  if (!html) html = "";
  console.log(html);
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}


export default function UploadResumeModal(props) {
  const { user, visible, resumeAction, resume, options, onClose,history } = props;
  const [step, setStep] = useState(0);
  const [uploadResume, setUploadResume] = useState({
    jobs: [],
    projects: [],
    edus: [],
  });
  const [ intent, setIntent] = useState({});
  const [ works, setWorks] = useState([]);
  const [ projects, setProjects] = useState([]);
  const [ edus, setEdus] = useState([]);
  const dispatch = useDispatch();

  let workRefs = [];
  let projectRefs = [];
  let educationRefs = [];

  useEffect(()=>{
    if(!visible) setStep(0);
  }, [visible])

  useEffect(()=>{
    setWorks(resume.jobs);
    setProjects(resume.projects);
    setEdus(resume.edus);
    setIntent( resume.intents && resume.intents.length !== 0 ? resume.intents[0] : {});
  }, [resume]);

  const allSteps = 4;
  const baseInfoRef = useRef(null);
  const intentRef = useRef(null);
  const workRef = useRef(null);
  const projectRef = useRef(null);
  const educationRef = useRef(null);

  const refs = [baseInfoRef, intentRef, workRefs, projectRefs, educationRefs];
  
  const saveResume = () => {
    console.log(refs, "refs")
    if (step < 2) {
      let submitPromise = refs[step].current.onSubmit;
      if (submitPromise) {
        submitPromise().then(values => {
          fieldFormNext(values);
        })
      } else {
        setStep(step + 1);
      }
    } else {
      if (step!=4&&refs[step].length === 0) {
        setStep(step + 1);
      } else {
        let submitPromises = refs[step].map(item => item.onSubmit())
        Promise.all([
          ...submitPromises
        ]).then(values => {
          console.log(values)
          fieldFormNext(values);
        });
      }
    }
  }

  const prevSteps = () => {
    setStep(step - 1)
  }

  const fieldFormNext = (values) => {
    if (step === 0) {
      setUploadResume({ ...uploadResume, basic: { ...values } });
    } else if (step === 1) {
      setUploadResume({ ...uploadResume, intent: { ...values } });
    } else if (step === 2) {
      uploadResume.jobs = values.filter(item => item);
      setUploadResume({ ...uploadResume, jobs: [...uploadResume.jobs] });
    } else if (step === 3) {
      uploadResume.projects = values.filter(item => item);
      setUploadResume({ ...uploadResume, projects: [...uploadResume.projects] });
    } else if (step === 4) {
      uploadResume.edus = values.filter(item => item);
      setUploadResume({ ...uploadResume, edus: [...uploadResume.edus] });
    }
    console.log("Received values of form: ", step, values);
    console.log("Received values of formupload: ", uploadResume);
    if (step === 4) {
      resumeAction.editResume({ ...uploadResume, is_upload: 1 }).then(res => {
        if (res.code === 0) {
          onClose();
          console.log('history111',history)
          debugger;
          // dispatch({type: "user/isOpenResumeSet", payload: {}})
          if (window.location.pathname === "/center") {
            history&&history.push({pathname :'/resume',query :{isLoad:true}})
            // window.location.href = "/resume";
          }else{
            dispatch({type: "user/isOpenResumeSet", payload: {}})
          }
        } else {
          message.error(res.errorMsg);
        }
      });
    } else {
      setStep(step + 1);
    }
  }

  const removeView = (type, index) => {
    if (type === "work") {
      works[index] = null;
      setWorks([...works]);
    } else if (type === "project") {
      projects[index] = null;
      setProjects([...projects]);
    } else if (type === "edu") {
      edus[index] = null;
      setEdus([...edus]);
    }
  }
  
  return (
    <Modal width={900} title="上传成功 - 设置" className="upload-resume-modal" maskClosable={false}
      footer={<React.Fragment>
        <Button type="primary" onClick={prevSteps} style={{marginRight: "10px", visibility: step !== 0 ? "visible" : "hidden"}}>上一步</Button>
        <Button type="primary" onClick={saveResume}>{step === allSteps ? "保存" : "下一步"}</Button>
      </React.Fragment>}
      onCancel={onClose} destroyOnClose visible={visible} >
      <Steps current={step} className="upload-resume-steps">
        <Step title="基本信息" />
        <Step title="求职意向" />
        <Step title="工作经历" />
        <Step title="项目经验" />
        <Step title="教育经历" />
      </Steps>
      <div className={`baseinfo-wrapper ${step === 0 ? "" : "forever"}`} >
        <BaseInfoFormWrapper isUpload={true} wrappedComponentRef={baseInfoRef}
          resume={resume} options={options} resumeAction={resumeAction}/>
      </div>
      <div className={`intent-wrapper ${step === 1 ? "" : "forever"}`}>
        <IntentFormWrapper isUpload={true} wrappedComponentRef={intentRef}
          resume={intent} options={options} resumeAction={resumeAction}/>
      </div>
      <div className={`work-wrapper ${step === 2 ? "" : "forever"}`}>
        {
          works && works.length !== 0 ? works.map((item, index) => {
            if (!item) return null;
            let nowItemLength = works.filter(item=>item).length;
            return <React.Fragment>
              {index !== 0 && <div className="upload-split-line"></div>}
              <WorkFormWrapper isUpload={true} index={index} isLast={nowItemLength === 1}
                wrappedComponentRef={(ref) => {
                  workRefs[index] = ref
                }}
                resume={item} options={options} resumeAction={{...resumeAction, removeView}}/>
            </React.Fragment>
          }) : <CustomizeRenderEmpty description="未解析到数据"/>
        }
      </div>
      <div className={`project-wrapper ${step === 3 ? "" : "forever"}`}>
        {
          projects && projects.length !== 0 ? projects.map((item, index) => {
            if (!item) return null;
            return <React.Fragment>
              {index !== 0 && <div className="upload-split-line"></div>}
              <ProjectFormWrapper isUpload={true} index={index} isLast={projects.filter(item=>item).length === 1}
                wrappedComponentRef={(ref) => {
                  projectRefs[index] = ref
                }}
                commonResume={uploadResume} resume={item} options={options} resumeAction={{...resumeAction, removeView}}/>
            </React.Fragment>
          }) : <CustomizeRenderEmpty description="未解析到数据"/>
        }
      </div>
      <div className={`education-wrapper ${step === 4 ? "" : "forever"}`}>
        {
          edus && edus.length !== 0 ? edus.map((item, index) => {
            if (!item) return null;
            return <React.Fragment>
              {index !== 0 && <div className="upload-split-line"></div>}
              <EducationFormWrapper isUpload={true} index={index} isLast={edus.filter(item=>item).length === 1}
                wrappedComponentRef={(ref) => {
                  educationRefs[index] = ref
                }}
                resume={item} options={options} resumeAction={{...resumeAction, removeView}}/>
            </React.Fragment>
          }) : <CustomizeRenderEmpty description="未解析到数据"/>
        }
      </div>
    </Modal>
  );
}
