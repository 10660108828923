import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, Form, Select, message, Input, Row, Col, Modal, DatePicker } from "antd";
import moment from "moment";
import { useOperateView } from "./resume-util";
import {requestCompany} from "services/resumeService";

const { MonthPicker } = DatePicker;
const FormItem = Form.Item;
const size = "large";
const { Option } = Select;

function createMarkup(html = "") {
  if (!html) html = "";
  console.log(html);
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}

export const ProjectForm= forwardRef((props, ref) =>  {
  const { getFieldDecorator, getFieldValue, validateFieldsAndScroll } = props.form;
  const { resumeAction, changeView, viewStatus, history, isLast, index,
    isUpload, form, resume, commonResume,
    options = { basic: {}, intent: {} } } = props;

  useImperativeHandle(ref, () => ({
    form, onSubmit
  }));

  const cancelView = () => {
    if (viewStatus === 1) {
      changeView(3);
    } else if (viewStatus === 2) {
      resumeAction.removeView();
    }
  }
  const overOperate = useOperateView(commonResume, false, () => cancelView());
  const delOperate = () => {
    Modal.confirm({
      title: '确定要删除该项目经验吗?',
      okText: '确定',
      cancelText: "取消",
      onOk() {
        resumeAction.removeView("project", index);
      },
      onCancel() {
      },
    })
  };
  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.id = resume.id;
        values = processValues(values)
        console.log("Received values of form: ", values, history, );
        let param = {};
        param.projects = [{...values}];
        resumeAction.editResume(param).then(res => {
          if (res.code === 0) {
            overOperate();
          } else {
            message.error(res.errorMsg);
          }
        });
      }
    });
  };

  const onSubmit = () => {
    return new Promise((resolve, reject) => {
     validateFieldsAndScroll((err, values) => {
        if (!err) {
          values = processValues(values)
          resolve(values);
        } else {
          reject(err);
        }
      });
    });
  };

  const processValues = (values) => {
    if (values.start_date) 
      values.start_date = values.start_date.format("YYYY-MM");
    if (values.end_date) 
      values.end_date = values.end_date.format("YYYY-MM");
    return values;
  }

  const disabledStartDate = (current) => {
    let end_date = getFieldValue("end_date");
    if (!current) {
      return false;
    }

    if (!end_date) {
      return current.valueOf() > moment().valueOf();
    }
    return (
      current.valueOf() > end_date.valueOf() ||
      current.valueOf() > moment().valueOf()
    );
  }


  const disabledEndDate = (current) => {
    let start_date = getFieldValue("start_date");
    if (!current) {
      return false;
    }
    if (!start_date) {
      // 如果没有选开始时间, 则可选时间不能大于当前时间
      return current.valueOf() > moment().valueOf();
    }
    // 如果有开始时间，则不能小于开始时间且大于当前时间
    return (
      current.valueOf() < start_date.valueOf() ||
      current.valueOf() > moment().valueOf()
    );
  }

  return (
    <Form className="resume-form baseinfo-form-content" onSubmit={handleSubmit}>
        {!isUpload && <div className="form-header">
          {viewStatus === 2 ? "添加项目经验" : ""}
          {viewStatus === 1 ? "编辑项目经验" : ""}
        </div>}
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="项目名称">
              {getFieldDecorator("name", {
                initialValue: resume.name,
                rules: [
                  { required: true, message: "请输入项目名称" },
                ]
              })(
                <Input
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入项目名称"
                  size={size}
                />
              )}
            </FormItem>
          </Col>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="所属公司" colon={false}>
              {getFieldDecorator("company", {
                initialValue: resume.company ? resume.company : undefined,
                rules: [{ required: true, message: "请选择所属公司" }]
              })(
                <Select placeholder="请选择行业" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    commonResume.jobs && commonResume.jobs.map(item => {
                      return <Option key={item.id} value={item.company}>{item.company}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col  className="resume-form-col special-date-col">
            <FormItem className="form-item time-item" label="项目时间">
              {getFieldDecorator("start_date", {
                initialValue: resume.start_date ? moment(resume.start_date, "YYYY-MM") : undefined,
                rules: [{ required: true, message: "请选择开始时间" }]
              })(
                <MonthPicker size={size}  format="YYYY-MM" 
                  disabledDate={disabledStartDate}
                  placeholder="开始时间" getPopupContainer={triggerNode => triggerNode.parentElement}/>
              )}
            </FormItem>
            <FormItem className="form-item font-item" label="">
              {getFieldDecorator("nothing", {
              })(
                <div>至</div>
              )}
            </FormItem>
            <FormItem className="form-item leave-item" label="">
              {getFieldDecorator("end_date", {
                initialValue: resume.end_date && resume.end_date !== "0000-00" && resume.end_date !== "至今" ? moment(resume.end_date, "YYYY-MM") : undefined,
                // rules: [{ required: true, message: "请选择结束时间" }]
              })(
                <MonthPicker size={size}  format="YYYY-MM" 
                  disabledDate={disabledEndDate}
                  placeholder="结束时间" getPopupContainer={triggerNode => triggerNode.parentElement}/>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <FormItem className="form-item" label="项目描述">
              {getFieldDecorator("work_desc", {
                initialValue: resume.work_desc,
                rules: [{ required: false, message: "请输入项目描述" }]
              })(
                <Input.TextArea rows={6}
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入项目描述"
                />
              )}
            </FormItem>
        </Row>
        {isUpload && !isLast &&
          <Row className="operate-row clear">
            <span onClick={delOperate} className="right delete-item"><img className="delete-img" src={require("assets/imgs/resume/icon-delete.png")}/>删除此条信息</span>
          </Row>
        }
        {!isUpload && 
          <Row className="operate-row clear">
            <Button className="save-btn" type="primary" size={size} htmlType="submit">保存</Button>
            <Button className="cancel-btn" size={size} onClick={overOperate}>取消</Button>
          </Row>
        }
    </Form>
  );
})

export function ProjectView(props) {
  const { commonResume, resume, options, resumeAction, index } = props;
  const [hover, setHover] = useState(false);
  const [viewStatus, setViewStatus] = useState(3); // 1编辑2添加3视图

  const changeView = (viewStatus) => {
    if (viewStatus === 1) {
      setHover(false);
    }
    setViewStatus(viewStatus)
  }
  useEffect(() => {
    setViewStatus(resume.isNew ? 2 : 3);
  }, [resume.isNew])
  const editOperate = useOperateView(commonResume, true, () => changeView(1));
  const delOperate = useOperateView(commonResume, true, () => deleteItem());
  const overOperate = useOperateView(commonResume, false, () => resumeAction.removeView(index));
  const cancelOperate = useOperateView(commonResume, false);
  const deleteItem = () => {
    Modal.confirm({
      title: '确定要删除该项目经验吗?',
      okText: '确定',
      cancelText: "取消",
      onOk() {
        resumeAction.delResume({
          obj_id: resume.id,
          type: 2
        }).then(res => {
          if (res.code === 0) {
            overOperate();
          } else {
            message.error(res.errorMsg);
          }
        })
      },
      onCancel() {
        cancelOperate();
      },
    })
  }
  const mouseEnter = (index) => {
    setHover(true);
  }
  const mouseLeave = (index) => {
    setHover(false);
  }
  return (
    <React.Fragment>
      {viewStatus === 3 && <div className="work-item-wrapper">
        <div 
            className={`work-item ${hover ? 'hover' : ''}`}
            onMouseOver={() => mouseEnter()} onMouseLeave={() => mouseLeave()}>
            {hover ? <div className="operate-area">
              <span className="edit-area" onClick={editOperate}>
                <img className="edit-img" src={require("assets/imgs/resume/icon-edit.png")}/>编辑
              </span>
              <span className="delete-area" onClick={delOperate}>
                <img src={require('assets/imgs/resume/icon-delete.png')}/>删除
              </span>
            </div> : null}
            <div className="company">
              <span className="company-name ellipsis" title={resume.company}>{resume.name}</span>
              <span className="company-time">{resume.start_date2} - {resume.end_date2}</span>
            </div>
            <div className="work-info">{resume.company}</div>
            <div className="work-desc" dangerouslySetInnerHTML={createMarkup(resume.work_desc)}></div>
          </div>
          </div>
      }
      {(viewStatus === 1 || viewStatus === 2) && 
        <ProjectFormWrapper commonResume={commonResume} changeView={changeView} viewStatus={viewStatus} options={options} resume={resume} resumeAction={resumeAction}/>}
    </React.Fragment>
  );
}

export const ProjectFormWrapper = Form.create({})(ProjectForm);

export default function Project(props) {
  const { user, resumeAction, options, resume} = props;
  const [ items, setItems] = useState([]);
  
  useEffect(() => {
    setItems(resume.projects);
  }, [resume]);

  const addView = () => {
    if (!resume.projects) {
      resume.projects = [];
    }
    resume.projects.unshift({isNew: true});
    setItems([...resume.projects]);
  }
  const removeView = (id) => {
    if (id) {
      resume.projects.splice(id, 1);
    } else {
      resume.projects.shift();
    }
    setItems([...resume.projects]);
  }
  const addOperate = useOperateView(resume, true, () => {
    if (!resume.complete_ratio){
      message.warning("还有未编辑完的项目");
      return;
    }
    addView();
  });
  return (
    <div className="form-wrapper resume-item-wrapper project-wrapper">
      <a id="project"></a>
      <div className="resume-item-header">
        <span className="title-line"></span>
        <span className="head-title">项目经验</span>
        <div className="add-area" onClick={addOperate}><img src={require('assets/imgs/resume/icon-add.png')}/>添加</div>
      </div>
      <div className="work-view-content">
        <div className="work-list">
          {
            items && items.map((item, index) => {
              return <ProjectView index={index} resume={item} commonResume={resume} options={options} resumeAction={{...resumeAction, removeView}}/>
            }) 
          }
        </div>
      </div>
    </div>
  );
}
