import React from "react";
import { connect } from "react-redux";
import { Layout, Carousel, Empty, Input, Button, Tabs } from "antd";
import CustomIcon from "components/CustomIcon";
import MenuSelection from "components/MenuSelection/MenuSelection";
import CascaderMore from "components/CascaderMore/CascaderMore";
import userModel from "store/reducers/userModel";
import indexModel from "store/reducers/indexModel";
import positionModel from "store/reducers/positionModel";
import AuthPanel from "./authPanel";
import { CompanyCard } from "./Card";
import { HotBusinessCard, HRCard } from "./Card";
import scrollTo from "utils/scrollTo"
import * as indexService from "services/indexService";
import { unique } from "utils/util";
import "./home.scss"

const { TabPane } = Tabs;
const { Header, Content, Footer } = Layout;

@connect(
  ({ index, user, positionModel }) => {
    return { ...index, user: user, positionModel: positionModel };
  },
  {
    ...userModel.actions,
    ...indexModel.actions,
    setUpdateState: positionModel.actions.setUpdateState
  }
)
export default class Home extends React.Component {
  // static async getInitialProps({ pathname, query = {}, req, res }) {
  //   console.log("dsadadsddddddd")
  //   return {};
  // }
  state = {
    indexImgs: [],
    tabIndex: "9",
    cityOptions: [],
    cascaderMoreOptions: [],
    jd_type: [
      {label: "最新发布",value: "4"},
      {label: "人事类",value: "1"},
      {label: "财务类",value: "2"},
    ]
  }

  componentDidMount() {
    document.title = '找工作-专注人事财务工作-即派优职';
    const { user, history, getOptions, positionModel } = this.props;
    const { params = {} } = positionModel;
    if (user.info.id) {
      history.push("/center")
      return;
    }
    getOptions(["function_type,cities"]).then(res => {
      const { cities = {} } = res;
      let option = [{ label: "热门城市", data: cities.hots }, { label: "全部省份", data: cities.city }]
      this.setState({ cascaderMoreOptions: option })
    });
    indexService.getIndexImg({ channel_id: 3 }).then(res => {
      if (res.code === 0 && res.data) {
        this.setState({ indexImgs: res.data })
      }
    });
    this.getData(params.citySel, "citySel");
  }
  getData = (val, name) => {
    const { getList, positionParams, indexUpdateState } = this.props;
    
    let params = { ...positionParams }
    if (name === "citySel") {
      params.city_id = (val || {}).id
    } else if (name === "type" && (val === "4" )) {
      params[name] = undefined;
      params.order_by = "publish_time";
    } else {
      params[name] = val
    }
    getList(params);
    indexUpdateState({
      filed: "positionParams",
      value: params
    });
  }

  componentWillUnmount() {

  }
  onChangeTab = (key) => {
   
    this.getData(key, "type");
    this.setState({ tabIndex: key })
  }
  update = (val) => {
    const { setUpdateState, positionModel } = this.props;
    const { params = {} } = positionModel;
    const { citySel = {} } = params;
    let paramsChange = { ...params, ...val }
    setUpdateState({
      filed: "params",
      value: paramsChange
    });
  }
  onChangeVal = (name, value) => {
    let params = {}
    if (name === "citySel") {
      params.citySel = value[0];
    }
    if (value === "keyword") {
      params[value] = name.target.value;
    }
    this.update(params)
  }
  // 更多---选择的城市
  onCascaderCity = (val = []) => {
    const { setUpdateState, positionModel } = this.props;
    const { params = {} } = positionModel;
    const { citySel = {} } = params;
    let paramsChange = { ...params, citySel: val[0] }
    setUpdateState({
      filed: "params",
      value: paramsChange
    });
    this.getData(val[0], "citySel")
  }
  //点击的菜单选项
  onCheckout = (item, parentItem) => {
    this.update({ function_type: item.value, defaultType: { value: parentItem.value, label: parentItem.label } });
    this.go();
  }
  //去职位
  go = (type = 0) => {
    const { history, user } = this.props
    if (type === 1) {
      if (!user.info.id) {
        if (!user.info.id) history.replace({ pathname: "/login", state: { target: "/positioned" } })
      }
      return;
    }
    history.push("/position");
  }
  nameLength = (obj = {}) => {
    return obj.name && obj.name.length
  }
  onDetail = (item) => {
    const { history } = this.props
    // history.push("/positionDetail/" + item.id)
    window.open("/positionDetail/" + item.id)
  }
  render() {
    const { history, optionsObj, positionModel, dataObj, phoneLogin } = this.props;
    const { list = [] } = dataObj;
    const { cities = {}, function_type } = optionsObj;
    const { params = {} } = positionModel;
    const { citySel = {} } = params;
    const { indexImgs, tabIndex, cityOptions, cascaderMoreOptions, jd_type = [] } = this.state;
    return (
      <div className="home-content">
        <AuthPanel userAction={{ phoneLogin }} history={history}></AuthPanel>
        <div className="container-g">
          <div className="search-box">
            <div className="left-side">
              <MenuSelection onClick={(e, parentItem) => { this.onCheckout(e, parentItem) }} options={function_type || []} />
            </div>
            <div className="right-side">
              <div className="search-box">
                <div className="left-input">
                  <div className="city">
                    <CustomIcon type="icon-map" style={{ fontSize: "20px",marginRight:"5px" }} /><CascaderMore onChange={(e) => this.onCascaderCity(e)} options={cascaderMoreOptions} type="2" number="1" single={true} value={[citySel]} /> <span className="line" />
                  </div>
                  <Input onPressEnter={(e) => { this.onChangeVal(e, 'keyword'); this.go() }} onBlur={(e) => this.onChangeVal(e, 'keyword')} style={this.nameLength(citySel) == 3 ? { paddingLeft: "120px" } : this.nameLength(citySel) > 3 ? { paddingLeft: "130px" } : {}} placeholder="请输入职位名称、企业名称" />
                </div>
                <div className="right-btn" onClick={() => { this.go() }}>
                  <Button type="primary">搜索</Button>
                </div>
              </div>
              <div className="home-banner">
                <Carousel autoplay dots={true} effect="fade" autoplaySpeed={5000}>
                  {indexImgs && indexImgs.length !== 0 ?indexImgs.map(item => {
                    return <div>
                      <div onClick={() => { if (!item.link_url) return; window.open(item.link_url) }}><img className="index-no-img" src={item.image_url} /></div>
                    </div>
                  }): <div style={{height: "450px"}}></div>
                  }
                </Carousel>
              </div>
            </div>
          </div>
          <div className="position-box">
            <Tabs defaultActiveKey={tabIndex} onChange={this.onChangeTab}>
              {jd_type.map(item => {
                return (
                  <TabPane tab={item.label} key={item.value}></TabPane>
                )
              })}
            </Tabs>
            {list.map((item, index) => {
              return (
                <CompanyCard item={item} onClick={() => { this.onDetail(item) }} key={item.id} style={{ marginRight: (index + 1) % 3 === 0 ? 0 : "20px" }} />
              )
            })}
            {!list.length && <div className="empty"><img src={require("assets/imgs/empty.png")} /> <div>暂无数据</div></div>}
          </div>
          <div className="go-position">
            <Button onClick={() => { this.go() }} >没有找到合适的职位？试试搜索功能吧！</Button>
          </div>
        </div>
      </div>
    );
  }
}
