import xhr from "./index";

/** 派服务 */
//发送服务
function sendNeed(param) {
  return xhr({ url: "/v4_0/jd/submit", body: param, method: "POST" });
}

export {
  sendNeed
};