import xhr from "./index";

export function positionList(param) {
  return xhr({ url: "/cpc/v1/jds", body: param, method: "get" });
}
export function sentList(param) {
  return xhr({ url: "/cpc/v1/jd-profiles", body: param, method: "get" });
}
export function collectList(param) {
  return xhr({ url: "/cpc/v1/mpc-logs", body: param, method: "get" });
}
export function positionDetail(param) {
  return xhr({ url: "/cpc/v1/jds/"+ param.id, body: param, method: "get" });
}
// 收藏
export function positionCollect(param) {
  return xhr({ url: "/cpc/v1/jd/collect", body: param, method: "POST" });
}
// 取消收藏
export function positionCollectCancel(param) {
  return xhr({ url: "/cpc/v1/jd/cancel-collect", body: param, method: "POST" });
}
// 投递
export function positionApplied(param) {
  return xhr({ url: "/cpc/v1/jd-profiles", body: param, method: "POST" });
}
