import Model from "../Model";
import dotProp from "dot-prop-immutable";
import * as userService from "services/userService";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const history = process.env.BROWSER ? require("utils/history").default : null;


export default Model.getInstance(
  class extends Model {
    namespace = "user";

    state = {
      info: {},

      loginRegistModal: false,
      loginRegistModalCallBack: null,

      login: {
        visible: false
      },

      register: {
        visible: false,
        completeInfo: {
          visible: false
        }
      },

      threePartLogin: {
        stateKey: "",
        visible: false
      },
      pathname: "",
      forgetPwd: {
        visible: false
      },
      centerInfo: {}, //个人中心信息
      option: {}, //编辑选项
      isNewStatus: false ,//新登录页状态
      resumeSetStatus: false ,//设置简历弹窗
    };

    actions = {
      async phoneLogin(param) {
        const res = await userService.login({
          ...param
        });
        if (res.code === 0) {
          this.dispatch({
            type: "setUserInfo",
            payload: res.data
          });
          //store user info to localStorage
          localStorage.setItem("user", JSON.stringify(res.data));

          // const pathname = this.getState().user.pathname;
        }
        return res;
      },

      async checkUserInfo() {
        const res = await userService.getUserInfo();
        if (res.code === 0) {
          this.dispatch({
            type: "setUserInfo",
            payload: res.data
          });
          //存储用户信息到localStorage上
          localStorage.setItem("user", JSON.stringify(res.data));
        } else if (res.code === 101 || res.code === 5112) {
          this.dispatch({
            type: "setUserInfo",
            payload: {}
          });
          //存储用户信息到localStorage上
          localStorage.removeItem("user");
        }
        return res;
      },

      async regist(param) {
        const res = await userService.regist(param);
        if (res.code === 0) {
        }
        return res;
      },

      async completeUserInfo(param) {
        const res = await userService.completeInfo({
          ...param,
        });
        if (res.code === 0) {
        }
        return res;
      },

      async forgetPwdReset(param) {
        const res = await userService.forgetPwdReset({
          ...param
        });
        if (res.code === 0) {
        }
        return res;
      },

      async resetPwd(param) {
        const res = await userService.resetPwd({
          new_password: param.password,
          code: param.validateCode,
          captcha: param.graphValidateCode
        });
        return res;
      },

      async logout() {
        const res = await userService.logout();
        if (res.code === 0) {
          this.dispatch({
            type: "setUserInfo",
            payload: {}
          });
          localStorage.removeItem("user");
          //职位详情退出保留当前页
          if (window.location.pathname.indexOf("positionDetail")!=-1) return;
          this.getState().user.pathname !== "/" ? history.replace("/") : !1;
        }
        return res;
      },

      async modifyUserPhone(param) {
        const res = await userService.modiyPhone({
          ...param,
          captcha: param.graphValidateCode
        });
        if (res.code === 0) {
          this.dispatch({
            type: "modifyUserInfo",
            payload: { key: "phone", value: param.phone }
          });
          const { info } = this.getState().user;
          localStorage.setItem("user", JSON.stringify(info));
        }
        return res;
      },

      async modifyUserEmail(param) {
        const res = await userService.modifyEmail({
          ...param,
          captcha: param.graphValidateCode
        });
        if (res.code === 0) {
          this.dispatch({
            type: "modifyUserInfo",
            payload: { key: "email", value: param.email }
          });
          const { info } = this.getState().user;
          localStorage.setItem("user", JSON.stringify(info));
        }
        return res;
      },
      
      async getPersonageInfo() {
        const res = await userService.personageInfo();
        if (res.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "centerInfo", value: res.data }
          });
        }
        return res;
      },

      async editCompany(param) {
        const res = await userService.editCompany(param);
        return res;
      },

      async companyCertification(param) {
        const res = await userService.companyCertification(param);
        return res;
      },

      async modifyPersonInfo(param) {
        const res = await userService.modifyPersonInfo(param);
        if (res.code === 0) {
          const { info } = this.getState().user;
          const newInfo = { ...info, ...param };
          this.dispatch({
            type: "setUserInfo",
            payload: newInfo
          });
          localStorage.setItem("user", JSON.stringify(newInfo));
        }
        return res;
      },

      async unbindThreePart(param) {
        const res = await userService.unbindThreePart(param);
        if (res.code === 0) {
          const { app_type } = param;
          this.dispatch({
            type: "modifyUserInfo",
            payload: {
              key:
                app_type === 1
                  ? "is_bind_wechat"
                  : app_type === 2
                  ? "is_bind_qq"
                  : "is_bind_weibo",
              value: 0
            }
          });
          const { info } = this.getState().user;
          localStorage.setItem("user", JSON.stringify(info));
        }
        return res;
      },
      async okResumeSet(param) {
        const res = await userService.setResume(param);
        console.log("ok")
        this.dispatch({
          type: "isOpenResumeSet",
          payload: {}
        });
        console.log(333,this)
        this.actions.checkUserInfo();
        return res;
      },
    };

    reducers = {
      setUserInfo(state, { payload: info }) {
        return dotProp.set(state, "info", info);
      },

      openLoginModal(state, { payload: data }) {
        return {...state, loginRegistModal: data.visible, loginRegistModalCallBack: data.callBack};
      },

      closeLoginModal(state) {
        return dotProp.set(state, "loginRegistModal", false);
      },

      toggleRegister(state, { payload: visible }) {
        return dotProp.set(state, "register.visible", visible);
      },

      toggleRegComplete(state, { payload: visible }) {
        return dotProp.set(state, "register.completeInfo.visible", visible);
      },

      toggleForgetPwd(state, { payload: visible }) {
        return dotProp.set(state, "forgetPwd.visible", visible);
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      toggleThreePart(state, { payload: visible }) {
        return dotProp.set(state, "threePartLogin.visible", visible);
      },

      setThPartStateKey(state, { payload: stateKey }) {
        return dotProp.set(state, "threePartLogin.stateKey", stateKey);
      },

      setThPartPathname(state, { payload: pathname }) {
        return dotProp.set(state, "threePartLogin.pathname", pathname);
      },

      modifyUserInfo(state, { payload: modifyItem }) {
        const { key, value } = modifyItem;
        return dotProp.set(state, `info.${key}`, value);
      },

      setPathname(state, { payload: pathname }) {
        return dotProp.set(state, "pathname", pathname);
      },
      isOpenResumeSet(state, { payload: data }) {
        return  {...state,resumeSetStatus:!state.resumeSetStatus};
      },
    };
  }
);
