import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, message, Tabs } from "antd";
import CustomIcon from "components/CustomIcon";
import PhoneCode from "./PhoneCode";
import AuthBg from "./AuthBg";
import userModel from "store/reducers/userModel";
import "./auth.scss"

const FormItem = Form.Item;
const { TabPane } = Tabs;
const size = "large";

function ResetForm(props) {
  const { getFieldDecorator, getFieldValue, validateFields } = props.form;
  const { userAction, history, modalClick  } = props;
  const { location={}} = history;
  const [activeKey, setActiveKey] = useState("1");
  const tabChange = (activeKey) => {
    setActiveKey(activeKey);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    
    validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        userAction.forgetPwdReset(values).then(res => {
          if (res.code === 0) {
            if (userAction.fromPanel) {
              if (userAction.modalCallBack) {
                userAction.modalCallBack();
              } else {
                modalClick(1, "2");
              }
            } else {
              message.success("新密码设置成功");
              history.push("/login", {tabType: "2"});
            }
          } else {
            message.error(res.errorMsg);
          }
        })
      }
    });
  }

  const leastSame = (rule, value, callback) => {
    let password = getFieldValue("password");
    let rePassword = getFieldValue("re_password");
    console.log(value, password, rePassword, "leastSame")
    if (rule.field === "password") {
      if (value && rePassword && value != rePassword) {
        callback("两次密码不一致");
      } else {
        callback()
      }
      return;
    } else if (rule.field === "re_password") {
      if (value && password && value != password) {
        callback("两次密码不一致");
      } else {
        callback()
      }
      return;
    }
    callback("")
    
  }
  const newPasswordChange = (e) => {
    let rePassword = getFieldValue("re_password");
    let password = e.target.value;
    console.log(rePassword, password, "213")
    setTimeout(() => {
      if (rePassword ) {
        validateFields(["re_password"])
      }
    }, 0);
    
  }
  const newRPasswordChange = (e) => {
    let password = getFieldValue("password");
    let rePassword = e.target.value;
    setTimeout(() => {
      if (password ) {
        validateFields(["password"])
      }
     
    }, 0);
  }

  const goLogin = () => {
    if (modalClick) {
      modalClick(1);
    } else {
      history.push("/login");
    }
  }

  return (
    <React.Fragment>
      <Tabs activeKey={activeKey} onChange={tabChange} className="login-tab">
        <TabPane tab="忘记密码" key="1">
        </TabPane>
      </Tabs>
      <Form className="verify-form auth-form reset-form" onSubmit={handleSubmit}>
        <FormItem className="form-item" label="">
          {getFieldDecorator("phone", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入手机号" },
              {pattern: new RegExp(/^1[3456789]\d{9}$/), message: "手机号码格式不正确"},
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_ph" style={{fontSize: "16px"}}/>}
              // onBlur={this.onBlurHandle}
              placeholder="请输入手机号"
              size={size}
            />
          )}
        </FormItem>
       
        <div className="special-input">
          <FormItem className="form-item" label="" >
            {getFieldDecorator("verify_code", {
              // initialValue: editClueData.company_name,
              rules: [
                { required: true, message: "请输入手机验证码" },
              ]
            })(
              <Input prefix={<CustomIcon type="icon-ic_yzm" style={{fontSize: "16px"}}/>}
                // onBlur={this.onBlurHandle}
                placeholder="请输入手机验证码"
                size={size}
                maxLength={6}
              />
            )}
          </FormItem>
          <PhoneCode className="register-get-code" formRef={props.form} />
        </div>
        <FormItem className="form-item" label="">
          {getFieldDecorator("password", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入新密码" },

            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_mima" style={{fontSize: "16px"}}/>}
              // onBlur={this.onBlurHandle}
              placeholder="请输入新密码(至少6位，数字、字母或'_')"  type="password"
              size={size} 
              onChange={newPasswordChange}
            />
          )}
        </FormItem>
        <FormItem className="form-item" label="">
          {getFieldDecorator("re_password", {
            // initialValue: editClueData.company_name,
            rules: [
              { required: true, message: "请输入新密码", validateTrigger: "onChange" },
              {
                validator: leastSame
              }
            ]
          })(
            <Input prefix={<CustomIcon type="icon-ic_mima" style={{fontSize: "16px"}}/>}
              // onBlur={this.onBlurHandle}
              placeholder="请输入新密码(至少6位，数字、字母或'_')"  type="password"
              size={size}
              onChange={newRPasswordChange}
            />
          )}
        </FormItem>
        
        <Button className="submit-btn" type="primary" block htmlType="submit" size={size}>提交</Button>
        <div className="reset-login">已有账号, <span style={{cursor: "pointer"}} onClick={goLogin}><a>马上登录</a><CustomIcon type="icon-enter"/></span></div>
      </Form>
    </React.Fragment>
  );
}

export const ResetPanel = Form.create({})(ResetForm);

@connect(
  ({ user }) => {
    return { user: user };
  },
  {
    ...userModel.actions,
  }
)
export default class AuthPanel extends React.Component {
  render () {
    const {history, forgetPwdReset} = this.props;
    const Reset = Form.create({})(ResetForm);
  
    return (
      <AuthBg height="545" history={history}>
        <Reset history={history} userAction={{forgetPwdReset}}/>
      </AuthBg>
    );
  }
}
