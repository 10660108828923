import Model from "../Model";

export default Model.getInstance(
  class extends Model {
    namespace = "TicTacToeChild";

    state = {
      param: "",
      pathname: ""
    };

    actions = {
      async asyncA(index) {
        return await new Promise((resolve, reject) => {
          setTimeout(() => {
            this.dispatch({
              type: "reducerA",
              payload: index
            });
            resolve("complete");
          }, 1000);
        });
      }
    };

    reducers = {
      reducerA(state, { payload: index }) {
        return state;
      },

      reducerB(state, { payload: step }) {
        return state;
      }
    };
  }
);
