import React from "react";
import { Switch, Route } from "react-router-dom";
import BasicLayout from "pages/layout/BasicLayout";
import { Abnormal404, Abnormal500 } from "pages/abnormal/abnormal";
import Login from "pages/auth/login";
import Register from "pages/auth/register";
import Home from "pages/home/home";
import Agreement from "pages/agreement/agreement";
import Center from "pages/home/center";
import Resume from "pages/resume/resume";
import Reset from "pages/auth/resetPassword";
import PositionDetail from "pages/positionDetail/positionDetail";
import {NoLoginPosition,LoginPosition} from "pages/position/position";
import Live from "pages/live/live";
import LiveRoom from "pages/live/live-room";


const Routes = () => (
  <Switch>
    <Route path="/login" component={Login} noAuth={true} />
    <Route path="/register" component={Register} noAuth={true} />
    <Route path="/reset" component={Reset} noAuth={true}/>
    <Route path="/liveroom/:id" component={LiveRoom} noAuth={true} noRequest={true}/>
    <Route path="/" component={BasicLayout} >
      <Route path="/" component={Home} exact noAuth={true} firstLevel="1"/>
      <Route path="/live" component={Live} noAuth={true} firstLevel="4" noRequest={true}/>
      <Route path="/position" component={NoLoginPosition} firstLevel="2" noAuth={true}/>
      <Route path="/positionDetail/:id"  component={PositionDetail} firstLevel="2" noAuth={true}/>
      <Route path="/center" component={Center} firstLevel="1" secondLevel="1"/>
      <Route path="/positioned" component={LoginPosition} secondLevel="2" />
      <Route path="/resume" component={Resume} firstLevel="3" secondLevel="3"/>
      
      <Route path="/agreement" component={Agreement} noAuth={true} noRequest={true}/>
      <Route path="/500" component={Abnormal500} noAuth={true} noRequest={true}/>
      <Route component={Abnormal404} noAuth={true} noRequest={true}/>
    </Route>
  </Switch>
);

export default Routes;
