import xhr from "./index";


export function login(param) {
  return xhr({ url: "/cpc/v1/user/login", body: param, method: "POST" });
}

export function sendMsg(param) {
  return xhr({ url: "/cpc/v1/user/send-msg", body: param, method: "POST" });
}

export function getUserInfo() {
  return xhr({
    url: "/cpc/v1/user/info",
    method: "GET"
  });
}

export function verifyUserPhone(param) {
  return xhr({ url: "/cpc/v1/user/verify-code", body: param, method: "POST" });
}

export function regist(param) {
  return xhr({ url: "/cpc/v1/user/register", body: param, method: "POST" });
}

export function forgetPwdReset(param) {
  return xhr({ url: "/cpc/v1/user/reset-pwd", body: param, method: "POST" });
}

export function logout() {
  return xhr({ url: "/cpc/v1/user/logout", method: "POST" });
}

export function completeInfo(param) {
  return xhr({ url: "/cpc/v1/users/" + param.id, body: param, method: "PUT" });
}
//简历隐私设置
export function setResume(param) {
  return xhr({ url: "/cpc/v1/profile/setting", body: param, method: "POST" });
}


function quickLogin(param) {
  return xhr({ url: "/v4_0/user/authLogin", body: param, method: "POST" });
}

function sendQuickMsg(param) {
  return xhr({ url: "/v4_0/user/sendAuthMsg", body: param, method: "POST" });
}


function getSmsWithGrap(param) {
  return xhr({ url: "/v4_0/user/sendMsg", body: param, method: "POST" });
}

function resetPwd(param) {
  return xhr({ url: "/v4_0/user/resetPwd", body: param, method: "POST" });
}
//个人中心信息
function personageInfo(param) {
  return xhr({ url: "/v4_0/center/index", body: param, method: "GET" });
}
//个人中心公司编辑
function editCompany(param) {
  return xhr({ url: "/v4_0/center/editCompany", body: param, method: "POST" });
}
//公司认证
function companyCertification(param) {
  return xhr({ url: "/v4_0/center/toCheck", body: param, method: "POST" });
}


function threepartSms(param) {
  return xhr({
    url: "/v4_0/register/bindSendMsg",
    body: param,
    method: "POST"
  });
}



function modiyPhone(param) {
  return xhr({ url: "/v4_0/user/editPhone", body: param, method: "POST" });
}

function getEmailCode(param) {
  return xhr({ url: "/v4_0/user/sendEmailCode", body: param, method: "POST" });
}

function modifyEmail(param) {
  return xhr({ url: "/v4_0/user/editEmail", body: param, method: "POST" });
}

function modifyPersonInfo(param) {
  return xhr({ url: "/v4_0/center/editInfo", body: param, method: "POST" });
}

function unbindThreePart(param) {
  return xhr({ url: "/v4_0/center/unbind", body: param, method: "POST" });
}

function getThreePartInfo(param) {
  return xhr({ url: "/v4_0/user/thirdInfo", body: param, method: "GET" });
}

export {
  quickLogin,
  sendQuickMsg,
  getSmsWithGrap,
  resetPwd,
  threepartSms,
  modiyPhone,
  getEmailCode,
  modifyEmail,
  personageInfo,
  editCompany,
  companyCertification,
  modifyPersonInfo,
  unbindThreePart,
  getThreePartInfo
};
