import xhr from "./index";

export function requestResumeDetail(param) {
  return xhr({ url: "/cpc/v1/profile/details", body: param, method: "GET" });
}

export function submitResume(param) {
  return xhr({ url: "/cpc/v1/profile/save", body: param, method: "POST" });
}

export function deleteResume(param) {
  return xhr({ url: "/cpc/v1/profile/del", body: param, method: "POST" });
}

export function requestCompany(param) {
  return xhr({ url: "/cpc/v1/companies", body: param, method: "GET" });
}

export function submitResumeSetting(param) {
  return xhr({ url: "/cpc/v1/profile/setting", body: param, method: "POST" });
}