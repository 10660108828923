import Model from "../Model";
import dotProp from "dot-prop-immutable";
import * as positionService from "services/positionService";
import { getCommonData } from "services/commonService";


export default Model.getInstance(
  class extends Model {
    namespace = "index";

    state = {
      optionsObj: {},
      dataObj: {},
      positionParams: {
        "per-page": 9,
        "order_by": "publish_time"
      },
      positionedParams: {
        "per-page": 10,
      }
    };

    actions = {
      async getOptions(param) {
        const res = await getCommonData(param);
        
        this.dispatch({
          type: "index/indexUpdateState",
          payload: {
            filed: "optionsObj",
            value: res
          }
        });
        return res
      },
      async getList(param) {
        param.expand = "education_name,publish_time_str,update_time_str,is_applied,work_time_name,salary_month,original_salary,salary_type_name,work_time_name,company";
        const res = await positionService.positionList(param);
        if (res.code === 0) {
          res.data.list.forEach(item => {
            item.joint = `${((item.address[0]||{}).city||{}).name} |  ${item.work_time_name} ${item.education_name && "|"} ${item.education_name}`
          })
          this.dispatch({
            type: "index/indexUpdateState",
            payload: {
              filed: "dataObj",
              value: res.data
            }
          });
        }
      },
      async getList2(param) {
        this.dispatch({
          type: "index/indexUpdateState",
          payload: {
            filed: "dataObj",
            value: {}
          }
        });
        param.expand = "company_name,jd_name,salary,jd"
        const res = await positionService.sentList(param);
        if (res.code === 0) {
          res.data.list =res.data.list.map(item => {
            item = { ...item, ...item.jd}
            console.log('item', item)
            item.joint = `${((item.address[0]||{}).city||{}).name} |  ${item.work_time_name} ${item.education_name && "|"} ${item.education_name}`
            return item
          })
          this.dispatch({
            type: "index/indexUpdateState",
            payload: {
              filed: "dataObj",
              value: res.data
            }
          });
        }
      },
      async getList3(param) {
        this.dispatch({
          type: "index/indexUpdateState",
          payload: {
            filed: "dataObj",
            value: {}
          }
        });
        param.expand = "publish_time_str,company_name,object_address,company_logo,jd"
        let convert = { ...param, action_type: 1, object_type: 1 }
        const res = await positionService.collectList(convert);
        if (res.code === 0) {
          res.data.list =res.data.list.map(item => {
            item = { ...item, ...item.jd}
            console.log('item', item)
            item.joint = `${((item.address[0]||{}).city||{}).name}  |  ${item.work_time_name} ${item.education_name && "|"} ${item.education_name}`
            return item
          })
          console.log(' res.data', res.data.list)
            this.dispatch({
              type: "index/indexUpdateState",
              payload: {
                filed: "dataObj",
                value: res.data
              }
            });
        }
      }
    };

    reducers = {
      indexUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
    };
  }
);
