/*
 * @Descripttion: 
 * @Date: 2021-06-21 14:52:02
 * @LastEditors: ci25578
 * @LastEditTime: 2021-06-22 21:16:21
 */
import React, { useState, useRef } from "react";
import { Menu, Popover, Dropdown } from "antd";
import useIsLogin from "hooks/useIsLogin";
import CustomIcon from "components/CustomIcon";

export default function BasicFooter() {
  const isLogin = useIsLogin();

  return (
    <div className="layout-footer-wrapper">
      <div className="layout-footer">
        <div className="service">
          <div><img src={require("assets/imgs/home/footer-logo.png")}/></div>
          <div className="service-phone">服务热线<span className="">400-600-6181</span></div>
          <div className="work-time">工作日<span>9:00-18:00</span></div>
        </div>
        <div className="about">
          <div className="title">关于即派</div>
          <div className="about-item"><a target="view_window" href="https://h.geetemp.com/infointroduction">即派简介</a></div>
          <div className="about-item"><a target="view_window" href="https://h.geetemp.com/infointroduction/dynamic">即派动态</a></div>
          <div className="about-item"><a target="view_window" href="https://h.geetemp.com/infointroduction/join">加入即派</a></div>
        </div>
        <div className="about help">
          <div className="title">帮助</div>
          <div className="about-item"><a target="view_window" href="/agreement">用户协议</a></div>
        </div>
        <div className="mobile">
          <div className="wechat-qrcode"><img src={require("assets/imgs/home/we-app.png")}/></div>
          <div className>
            <div><img className="scan-img" src={require("assets/imgs/home/wechat-scan.png")}/></div>
            <div className="bold wehao">即派服务号</div>
            <div className="slogan">找工作、找商机、找服务</div>
          </div>
        </div>
      </div>
      <div className="split-line"></div>
      <div className="icp">
        <div className="report">朝阳区人力资源与社会保障局 监督电话:57596212，65090445</div>
        <div className="info">
          <span>©2014-2016</span>
          <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">苏ICP备14059286号-2</a>
          <a href="https://www.geetemp.com/geetemp/default/business-license.jpg" target="_blank" rel="noopener noreferrer">营业执照</a>
          <a href="https://www.geetemp.com/geetemp/default/hrs-license.jpg" target="_blank" rel="noopener noreferrer">服务许可证名称320510190032</a>
          <span>科锐数字科技（苏州）有限公司 版权所有</span>
        </div>
      </div>
    </div>
  );
}
