/*
 * @Descripttion: 
 * @Date: 2021-06-21 14:52:02
 * @LastEditors: ci25578
 * @LastEditTime: 2021-06-22 20:52:02
 */
import React, {useState} from "react";
import { Link } from "react-router-dom";

export default function BasicFooter() {
  const [youzhiHover, setYouzhiHover]= useState(false);
  const [gboxHover, setGboxHover]= useState(false)
  const mouseYouzhiEnter = () => {
    setYouzhiHover(true)
  }
  const mouseYouzhiLeave = () => {
    setYouzhiHover(false)
  }
  const mouseGboxEnter = () => {
    setGboxHover(true)
  }
  const mouseGboxLeave = () => {
    setGboxHover(false)
  }
  return (
    <div className="layout-exfooter-wrapper">
      <div className="layout-exfooter">
        <div className="product">
          <div className="title">Geetemp旗下产品</div>
            <div className="our-xcx" onMouseEnter={mouseYouzhiEnter} onMouseLeave={mouseYouzhiLeave}>
              <a><img src={require("assets/imgs/home/youzhi-logo.png")}/><span>即派优职</span><span className="special">&nbsp;-&nbsp;求职招聘找工作</span></a>
              {youzhiHover && <img className="foot-youzhi" src={require("assets/imgs/foot-youzhi.png")} />}
            </div>
            <div className="our-xcx" onMouseEnter={mouseGboxEnter} onMouseLeave={mouseGboxLeave}>
              <a><img className="geebox-logo" src={require("assets/imgs/home/geebox-logo.png")}/><span>即派盒子</span><span className="special">&nbsp;-&nbsp;人力资源服务商聚合平台</span></a>
              {gboxHover && <img className="foot-gbox" src={require("assets/imgs/foot-gbox.png")} />}
            </div>
        </div>
        <div className="about">
          <div className="title">关于即派</div>
          <div className="about-item"><a target="view_window" href="https://h.geetemp.com/infointroduction">即派简介</a></div>
          <div className="about-item"><a target="view_window" href="https://h.geetemp.com/infointroduction/dynamic">即派动态</a></div>
          <div className="about-item"><a target="view_window" href="https://h.geetemp.com/infointroduction/join">加入即派</a></div>
        </div>
        <div className="mobile">
          <img src={require("assets/imgs/home/we-app.png")}/>
          <div className="name">即派官方公众号</div>
        </div>
        <div className="service">
          <div><img src={require("assets/imgs/home/foot-logo-trans.png")}/></div>
          <div className="service-phone">服务热线<span className="">400-600-6181</span></div>
          <div className="work-time">工作日<span>9:00-18:00</span></div>
        </div>
        <div className="split-line"></div>
        <div className="friend-link">
          <div className="report">朝阳区人力资源与社会保障局 监督电话:57596212，65090445</div>
          <div className="left">
            <span>友情链接</span>
            <a className="link" href="http://www.careerintlinc.com/" target="view_window">科锐国际</a>
            <a className="link" href="http://www.cohirer.com/" target="view_window">cohire</a>
            <a className="link" href="http://www.caikevip.com/" target="view_window">才客</a>
          </div>
          <div className="right">
            <span className="icp">©2014-2016</span>
            <a href="http://beian.miit.gov.cn" className="icp" target="_blank" rel="noopener noreferrer">苏ICP备14059286号-2</a>
            <a href="https://www.geetemp.com/geetemp/default/business-license.jpg" className="icp" target="_blank" rel="noopener noreferrer">营业执照</a>
            <a href="https://www.geetemp.com/geetemp/default/hrs-license.jpg" className="icp" target="_blank" rel="noopener noreferrer">服务许可证名称320510190032</a>
            <span className="icp">科锐数字科技（苏州）有限公司 版权所有</span>
          </div>
        </div>
      </div>
      
    </div>
  );
}
