import Model from "../Model";
import * as businessService from "services/businessService";
// import { message } from 'antd';

export default Model.getInstance(
  class extends Model {
    namespace = "businessModel";
    state = {
      businessList: [],
      businessMeta: {},
      businessCondition: {},
    };

    actions = {
      async getBusinessList(param) {
        this.dispatch({
          type: "businessModel/updateLoading",
          payload: true    
        });
        let condition = this.getState().businessModel.businessCondition;
        if (param.scene === 1 || param.scene === 4) {
          param.expand = "date,is_get,city_str,service_type_str,publisher_role_name";
        } else if (param.scene === 2) {
          param.expand = "city_str,service_type_str,real_status,share_num,has_get_unread,max_get_remind"; 
        } else if (param.scene === 3) {
          param.expand = "city_str,service_type_str,feedback,real_status,publisher_role_name,get_time,release_info"; 
        }
        param["per-page"] = param["per-page"] || 10;
        param = {...condition, ...param}
        let result = {};
        if (param.scene === 4) {
          param.type = 1;
          result = await businessService.getStarBusiness(param);
        } else {
          result = await businessService.getBusiness(param);
        }
        if (result.code == 0) {
          this.dispatch({
            type: "businessModel/updateList",
            payload: result.data  
          });
          this.dispatch({
            type: "businessModel/updateCondition",
            payload: param
          });
        }
        this.dispatch({
          type: "businessModel/updateLoading",
          payload: false
        });
      },
    };

    reducers = {
      updateList(state, { payload: data }) {
        return {
          ...state, 
          businessList: data.list, 
          businessMeta: data._meta, 
        };
      },
      updateCondition(state, { payload: data }) {
        return {
          ...state, 
          businessCondition: {...data}, 
        };
      },
    };
  }
);
