import xhr from "./index";
import cacheOptions from "../utils/cacheOptions";

export function getCommonDataWithoutCache(dataNameArray = []) {
  return xhr({
    url: `/v4_0/common/static`,
    body: { data_name: dataNameArray.join(",") },
    method: "GET"
  });
}

export const getCommonData = cacheOptions((dataNameArray = []) =>
  xhr({
    url: `/cpc/v1/common/static`,
    body: { data_name: dataNameArray.join(",") },
    method: "GET"
  })
);

/**
 * 广告接口
 */
export function ads(param) {
  return xhr({ url: "/v4_0/home/indexImg", body: param, method: "GET" });
}

/**
 * 获取系统信息
 */
export function getSystemInfo(param) {
  return xhr({ url: "/v4_0/message/list", body: param, method: "GET" });
}

/**
 * 意见反馈
 * @param {*} param
 */
export function feedback(param) {
  return xhr({ url: "/v4_0/home/feedback", body: param, method: "POST" });
}
/**
 * 根据职位名查询职位
 * @param {*} param
 */
export function getPositionName(param) {
  return xhr({ url: "/v4_0/jd/getJdsByName", body: param, method: "GET" });
}

//商机详情
export function businessDetail(param) {
  return xhr({
    url: "/cpc/v1/gb-businesses/" + param.id,body: param, method: "GET"});
}
//获取商机电话号码
export function phone(param) {
  return xhr({
    url: "/cpc/v1/gb-business/get" ,body: param, method: "POST"});
}
//备注进展
export function businessFeedback(param) {
  return xhr({
    url: "/cpc/v1/gb-business/feedback" ,body: param, method: "POST"});
}
//商机被领取详情列表
export function businessHasList(param) {
  return xhr({
    url: "/cpc/v1/gb-business/has-get",body: param, method: "get"});
}

// 
export function positionSearch(param) {
  return xhr({
    url: "/cpc/v1/common/position-search",body: param, method: "POST"});
}

export function requestLiveList(param) {
  return xhr({
    url: "/api/live/list",body: param, method: "GET"});
}