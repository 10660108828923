import React from "react";
import { connect } from "react-redux";
import { Pagination, Empty, Button, Message } from "antd";
import CustomIcon from "components/CustomIcon";
import PositionCard from "components/PositionCard/PositionCard";
import userModel from "store/reducers/userModel";
import indexModel from "store/reducers/indexModel";
import scrollTo from "utils/scrollTo"
import * as indexService from "services/indexService";
import * as positionService from "services/positionService";
import "./HomeList.scss"


@connect(
  ({ index, user }) => {
    return { ...index, user: user };
  },
  {
    ...userModel.actions,
    ...indexModel.actions
  }
)
export default class HomeList extends React.Component {
  state = {

  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    const { type } = this.props;

    if (type !== prevProps.type) {
      console.log("eeeee", type)
      this.getData();
    }
  }
  componentWillUnmount() {

  }
  getData = (page = 1, pageSize = 10, ) => {
    const { getList, getList2, getList3, type, positionedParams } = this.props;
    if (type === "1") {
      getList({
        ...positionedParams, page: page,
        "per-page": pageSize,
      })
    } else if (type === "2") {
      getList2({
        ...positionedParams, page: page,
        "per-page": pageSize,
      })
    } else {
      getList3({
        ...positionedParams, page: page,
        "per-page": pageSize,
      })
    }
  }
  go = (item) => {
    console.log('ttt', item)
    window.open("/positionDetail/" + item.id)
  }
  //调用子组件操作
  onHandleCard = (type, item) => {
    const { user ,dataObj,checkUserInfo} = this.props;
    const { _meta = {} } = dataObj;
    //投递
    if (type === 1) {
      positionService.positionApplied({ jd_ids: [item.id], profile_id: user.info.profile_id }).then(res => {
        if (res.code === 0) {
          this.getData(_meta.currentPage);
          checkUserInfo();
          Message.success("简历投递成功!")
        } else {
          Message.error(res.transformError || res.msg || res.data);
        }
      });

    }
    // 收藏
    if (type === 2) {
      positionService.positionCollectCancel({ jd_id: item.id }).then(res => {
        if (res.code === 0) {
          this.getData(_meta.currentPage);
          checkUserInfo();
        } else {
          Message.error(res.transformError || res.msg || res.data);
        }
      });
    }
  }

  render() {
    const { history, dataObj, type } = this.props;
    const { list = [], _meta = {} } = dataObj;
    return (
      <div className="home-list">
        {list.map((item, index) => {
          return <PositionCard  onClick={() => {this.go(item) }} onHandleCard={this.onHandleCard} key={item.id} item={item} type={type} />
        })}
        <Pagination current={_meta.currentPage || 1} pageSize={_meta.perPage || 10}
          total={_meta.totalCount} className="pagination"
          onChange={this.getData} hideOnSinglePage={true} />
        {!list.length && <div className="empty"><img src={require("assets/imgs/empty.png")} /> <div>暂无数据</div></div>}
      </div>
    )
  }
}
