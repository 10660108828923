import Model from "../Model";
import dotProp from "dot-prop-immutable";
import * as commonService from "services/commonService";

export default Model.getInstance(
  class extends Model {
    namespace = "common";

    state = {
      systemInfos: [],
      unread: 0,
      matchRoute: {},
    };

    actions = {
      async getSystemInfos(param) {
        const res = await commonService.getSystemInfo(param);
        if (res.code === 0) {
          this.dispatch({
            type: "setSystemInfos",
            payload: (res.data.list || []).map(item => ({
              type: item.type,
              typeName: item.type_name,
              time: item.create_time_str,
              title: item.title,
              content: item.content
            }))
          });
        }
      }
    };

    reducers = {
      setSystemInfos(state, { payload: systemInfos }) {
        return dotProp.set(state, "systemInfos", systemInfos);
      },
      setUnread(state, { payload: data }) {
        return {...state, unread: data}
      },
      setMatchRoute(state, { payload: data }) {
        return {...state, matchRoute: data}
      }
    };
  }
);
