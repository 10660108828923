import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button, Form, Select, message, Input, Row, Col, Checkbox, DatePicker, Modal } from "antd";
import moment from "moment";
import { useOperateView } from "./resume-util";

const FormItem = Form.Item;
const size = "large";
const { Option } = Select;
const { MonthPicker } = DatePicker;

function createMarkup(html = "") {
  if (!html) html = "";
  console.log(html);
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}

export const EducationForm = forwardRef((props, ref) => {
  const { getFieldDecorator, getFieldValue, validateFieldsAndScroll } = props.form;
  const { commonResume, changeView, viewStatus, resumeAction, history,  isLast, index,
    isUpload, form, resume, options = { basic: {}, intent: {}, edu: {} } } = props;
  useImperativeHandle(ref, () => ({
    form, onSubmit
  }));
  const cancelView = () => {
    if (viewStatus === 1) {
      
      changeView(3);
    } else if (viewStatus === 2) {
      resumeAction.removeView();
    }
  }
  const overOperate = useOperateView(commonResume, false, () => cancelView());
  const delOperate = () => {
    Modal.confirm({
      title: '确定要删除该教育经历吗?',
      okText: '确定',
      cancelText: "取消",
      onOk() {
        resumeAction.removeView("edu", index);
      },
      onCancel() {
      },
    })
  };
  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.id = resume.id;
        values = processValues(values);
        console.log("Received values of form: ", values, history);
        let param = {};
        param.edus = [{...values}];
        resumeAction.editResume(param).then(res => {
          if (res.code === 0) {
            overOperate();
          } else {
            message.error(res.errorMsg);
          }
        });
      }
    });
  };
  const onSubmit = () => {
    return new Promise((resolve, reject) => {
     validateFieldsAndScroll((err, values) => {
        if (!err) {
          values = processValues(values)
          resolve(values);
        } else {
          reject(err);
        }
      });
    });
  };
  const processValues = (values) => {
    if (values.start_date) 
      values.start_date = values.start_date.format("YYYY-MM");
    if (values.end_date) 
      values.end_date = values.end_date.format("YYYY-MM");
    values.is_fulltime = values.is_fulltime ? 1 : 0;
    values.is_oversea = values.is_oversea ? 1 : 0;
    return values;
  }
  const disabledStartDate = (current) => {
    let end_date = getFieldValue("end_date");
    if (!current) {
      return false;
    }

    if (!end_date) {
      return current.valueOf() > moment().valueOf();
    }
    return (
      current.valueOf() > end_date.valueOf() ||
      current.valueOf() > moment().valueOf()
    );
  }

  const disabledEndDate = (current) => {
    let start_date = getFieldValue("start_date");
    if (!current) {
      return false;
    }
    if (!start_date) {
      // 如果没有选开始时间, 则可选时间不能大于当前时间
      return current.valueOf() > moment().valueOf();
    }
    // 如果有开始时间，则不能小于开始时间且大于当前时间
    return (
      current.valueOf() < start_date.valueOf() ||
      current.valueOf() > moment().valueOf()
    );
  }
  return (
    <Form className="resume-form baseinfo-form-content" onSubmit={handleSubmit}>
        {!isUpload && <div className="form-header">添加基本信息</div>}
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="学校名称">
              {getFieldDecorator("school", {
                initialValue: resume.school,
                rules: [
                  { required: true, message: "请输入学校名称" },
                ]
              })(
                <Input
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入学校名称"
                  size={size}
                />
              )}
            </FormItem>
          </Col>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="学历" colon={false}>
              {getFieldDecorator("education", {
                initialValue: resume.education ? resume.education : undefined,
                rules: [{ required: true, message: "请选择学历" }]
              })(
                <Select placeholder="请选择学历" size={size} getPopupContainer={triggerNode => triggerNode.parentElement}>
                  {
                    options.edu.education && options.edu.education.map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col className="resume-form-col">
            <FormItem className="form-item" label="专业">
              {getFieldDecorator("major", {
                initialValue: resume.major,
                rules: [
                  { required: false, message: "请输入专业名称" },
                ]
              })(
                <Input
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入专业名称"
                  size={size}
                />
              )}
            </FormItem>
          </Col>
          <Col  className="resume-form-col special-date-col">
            <FormItem className="form-item time-item" label="时间段">
              {getFieldDecorator("start_date", {
                initialValue: resume.start_date ? moment(resume.start_date, "YYYY-MM") : undefined,
                rules: [{ required: true, message: "请选择入学时间" }]
              })(
                <MonthPicker size={size}  format="YYYY-MM" 
                  disabledDate={disabledStartDate}
                  placeholder="入学时间" getPopupContainer={triggerNode => triggerNode.parentElement}/>
              )}
            </FormItem>
            <FormItem className="form-item font-item" label="">
              {getFieldDecorator("nothing", {
              })(
                <div>至</div>
              )}
            </FormItem>
            <FormItem className="form-item leave-item" label="">
              {getFieldDecorator("end_date", {
                initialValue: resume.end_date && resume.end_date !== "0000-00" && resume.end_date !== "至今" ? moment(resume.end_date, "YYYY-MM") : undefined,
                // rules: [{ required: true, message: "请选择毕业时间" }]
              })(
                <MonthPicker size={size}  format="YYYY-MM" 
                  disabledDate={disabledEndDate}
                  placeholder="毕业时间" getPopupContainer={triggerNode => triggerNode.parentElement}/>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <FormItem className="form-item" label="专业描述">
              {getFieldDecorator("major_desc", {
                initialValue: resume.major_desc,
                // rules: [{ required: true, message: "请输入专业描述" }]
              })(
                <Input.TextArea rows={6}
                  // onBlur={this.onBlurHandle}
                  placeholder="请输入专业描述"
                />
              )}
            </FormItem>
        </Row>
        <Row className="edu-check-row">
          <Col  className="resume-form-col special-checkbox-col">
            <FormItem className="form-item" label="">
              {getFieldDecorator("is_fulltime", {
                initialValue: resume.is_fulltime,
                valuePropName: "checked",
                // rules: [
                //   { required: true, message: "请输入手机号" },
                // ]
              })(
                <Checkbox size={size}>该学历为统招学历</Checkbox>
              )}
            </FormItem>
          </Col>
          <Col  className="resume-form-col">
            <FormItem className="form-item" label="">
              {getFieldDecorator("is_oversea", {
                initialValue: resume.is_oversea,
                valuePropName: "checked",
                // rules: [{ required: true, message: "请输入手机验证码" }]
              })(
                <Checkbox size={size}>该学历为海外学历</Checkbox>
              )}
            </FormItem>
          </Col>
          {isUpload && !isLast &&
            <span onClick={delOperate} className="right delete-item"><img className="delete-img" src={require("assets/imgs/resume/icon-delete.png")}/>删除此条信息</span>
          }
        </Row>
        
        {!isUpload && 
          <Row className="operate-row clear">
            <Button className="save-btn" type="primary" size={size} htmlType="submit">保存</Button>
            <Button className="cancel-btn" size={size} onClick={overOperate}>取消</Button>
          </Row>
        }
    </Form>
  );
})

export function EducationView(props) {
  const {commonResume, resume, options, resumeAction, index } = props;
  const [hover, setHover] = useState(false);
  const [viewStatus, setViewStatus] = useState(3); // 1编辑2添加3视图

  const changeView = (viewStatus) => {
    if (viewStatus === 1) {
      setHover(false);
    }
    setViewStatus(viewStatus)
  }
  useEffect(() => {
    setViewStatus(resume.isNew ? 2 : 3);
  }, [resume.isNew])
  const editOperate = useOperateView(commonResume, true, () => changeView(1));
  const delOperate = useOperateView(commonResume, true, () => deleteItem());
  const overOperate = useOperateView(commonResume, false, () => resumeAction.removeView(index));
  const cancelOperate = useOperateView(commonResume, false);

  const deleteItem = () => {
    Modal.confirm({
      title: '确定要删除该教育经历吗?',
      okText: '确定',
      cancelText: "取消",
      onOk() {
        resumeAction.delResume({
          obj_id: resume.id,
          type: 3
        }).then(res => {
          if (res.code === 0) {
             overOperate();
          } else {
            message.error(res.errorMsg);
            cancelOperate();
          }
        })
      },
      onCancel() {
        cancelOperate();
      },
    })
  }
  const mouseEnter = (index) => {
    setHover(true);
  }
  const mouseLeave = (index) => {
    setHover(false);
  }
  let eduInfos = [];
  resume.education_name && eduInfos.push(resume.education_name);
  resume.major && eduInfos.push(resume.major);
  resume.is_fulltime && eduInfos.push("统招学历");
  resume.is_oversea && eduInfos.push("海外学历");
  return (
    <React.Fragment>
      {viewStatus === 3 && <div className="work-item-wrapper">
        <div
          className={`work-item ${hover ? 'hover' : ''}`}
          onMouseOver={() => mouseEnter()} onMouseLeave={() => mouseLeave()}>
          {hover ? <div className="operate-area">
            <span className="edit-area" onClick={editOperate}>
              <img className="edit-img" src={require("assets/imgs/resume/icon-edit.png")} />编辑
            </span>
            <span className="delete-area" onClick={delOperate}>
              <img src={require('assets/imgs/resume/icon-delete.png')} />删除
            </span>
          </div> : null}
          <div className="company">
            <span className="company-name ellipsis" title={resume.company}>{resume.school}</span>
            <span className="company-time">{resume.start_date2} - {resume.end_date2}</span>
          </div>
          <div className="work-info">{eduInfos.join(" | ")}</div>
          <div className="work-desc"
          dangerouslySetInnerHTML={createMarkup(resume.major_desc)}
          ></div>
        </div>
      </div>
      }
      {(viewStatus === 1 || viewStatus === 2) &&
        <EducationFormWrapper commonResume={commonResume} changeView={changeView} viewStatus={viewStatus} options={options} resume={resume} resumeAction={resumeAction}/>}
    </React.Fragment>
  );
}

export const EducationFormWrapper = Form.create({})(EducationForm);

export default function Education(props) {
  const { user, resumeAction, options, resume} = props;
  const [ items, setItems] = useState([]);
  useEffect(() => {
    setItems(resume.edus);
  }, [resume]);
  const addView = () => {
    if (!resume.edus) {
      resume.edus = [];
    }
    resume.edus.unshift({isNew: true});
    setItems([...resume.edus]);
  }
  const removeView = (id) => {
    if (id) {
      resume.edus.splice(id, 1);
    } else {
      resume.edus.shift();
    }
    setItems([...resume.edus]);
  }
  const addOperate = useOperateView(resume, true, () => {
    if (!resume.complete_ratio){
      message.warning("还有未编辑完的项目");
      return;
    }
    addView();
  });
  return (
    <div className="form-wrapper resume-item-wrapper education-wrapper">
      <a id="edu"></a>
      <div className="resume-item-header">
        <span className="title-line"></span>
        <span className="head-title">教育经历</span>
        <div className="add-area" onClick={addOperate}><img src={require('assets/imgs/resume/icon-add.png')}/>添加</div>
      </div>
      <div className="work-view-content">
        <div className="work-list">
          {
            items && items.map((item, index) => {
              return <EducationView index={index} commonResume={resume} resume={item} options={options} resumeAction={{...resumeAction, removeView}}/>
            }) 
          }
        </div>
      </div>
    </div>
  );
}
