import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import "./tabs.scss";

const { TabPane } = Tabs;

export default function tabs(props) {
  const {history} = props;
  const matchRoute = useSelector(state => state.common.matchRoute);
  const [activeKey, setActiveKey] = useState("1");
  const tabChange = (activeKey) => {
    setActiveKey(activeKey);
    if (activeKey === "1") {
      history.push("/center")
    } else if (activeKey === "2") {
      history.push("/positioned")
    } else if (activeKey === "3") {
      history.push("/resume")
    }
  }
  useEffect(() => {
    matchRoute.secondLevel &&  setActiveKey(matchRoute.secondLevel);
  }, [matchRoute])
  return (
    <div className="tabs-wrapper">
      <Tabs activeKey={activeKey} onChange={tabChange} className="second-tabs" size="default" tabBarStyle={{height: "50px"}}>
        <TabPane tab="首页" key="1">
        </TabPane>
        <TabPane tab="职位" key="2">
        </TabPane>
        <TabPane tab="简历" key="3">
        </TabPane>
      </Tabs>
    </div>
  )
}