import Model from "../Model";
import dotProp from "dot-prop-immutable";
import * as positionService from "services/positionService";

export default Model.getInstance(
  class extends Model {
    namespace = "positionModel";

    state = {
      dataObj:{},
      params:{
        page:1,
        "per-page":10,
        order_by:"update_time",
        function_type:"",
        citySel:{id:1,name:"全国",type: 0}
      },
      hostCityOption:[]
    };

    actions = {
      async getList(param) {
        param.city_id=(param.citySel||{}).id;
        param.expand="education_name,publish_time_str,update_time_str,is_applied,work_time_name,salary_month,original_salary,salary_type_name,work_time_name,company";
        let convert={...param};
        delete convert.citySel;
        const res = await positionService.positionList(convert);
        if (res.code === 0) {
          res.data.list&&res.data.list.forEach(item => {
            item.joint=`${((item.address[0]||{}).city||{}).name} |  ${item.work_time_name} ${item.education_name&&"|"} ${item.education_name}`
          })
          this.dispatch({
            type: "setData",
            payload: res.data
          });
        }
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      setData(state, { payload: data }) {
        return dotProp.set(state, "dataObj", data);
      },
    };
  }
);
