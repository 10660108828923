import Model from "../Model";
import * as sendSerciceS from "services/sendServiceS";
import * as commonService from "services/commonService";

export default Model.getInstance(
  class extends Model {
    namespace = "SendSerciceModal";
    state = {
      statusParam: { service_type: 3 },
      ads: []
    };

    actions = {
      async sendNeed(param) {
        let result = await sendSerciceS.sendNeed({ ...param });
        return result;
      },
      async adsImg() {
        const param = {
          scene_id: 1,
          page: 1
        };
        let result = await commonService.ads({ ...param });
        this.dispatch({
          type: "setUpdateState",
          payload: { filed: "ads", value: result.data }
        });
        return result;
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      }
    };
  }
);
