import React, { useState } from "react";
import { message } from "antd";
import { sendMsg } from "services/userService";

export default function PhoneCode(props) {
  const {className, formRef} = props;
  const [status, setStatus] = useState(61)
  const getCode = () => {
    let phone = formRef.getFieldValue("phone");
    console.log("codeclick")
    if (!new RegExp(/^1[3456789]\d{9}$/).test(phone)) {
      message.warning("请输入正确的手机号")
      return;
    }
    let count = 60;
    setStatus(count--);
    let countTime = setInterval(() => {
      if (count === 0) {
        setStatus(61);
        clearInterval(countTime);
        return;
      }
      setStatus(count--);
    }, 1000)
    sendMsg({
      phone: phone
    })
  }
  return (
    <div className={`${className} phone-code`}>
      {
        status === 61 ? <div className="get-code" onClick={getCode}>获取验证码</div> :  <div className="count-time">{status}s</div>
      }
    </div>
  )
}