
import React, { useState, useEffect } from 'react';
import Head from "./head"
import CustomIcon from "components/CustomIcon";
import { Radio, Input, Button, Modal, Icon, Message ,Spin} from 'antd';
import * as positionService from "services/positionService";
import { createMarkup } from "utils/util";
import { CompanyCard } from "pages/home/Card";
import { Link } from "react-router-dom";
import './style.scss';
const { TextArea } = Input;
export default function PositionDetail(props) {
  const { match = {} } = props;
  const { params = {} } = match;
  const [info, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  useEffect(() => {
    getDetail().then(res => {
      document.title = (res.data||{}).name || "找工作-专注人事财务工作-即派优职";
    });
  }, [])
  async function getDetail() {
    let res;
    let paramsInfo = {
      id: params.id,
      expand: "company,education_name,update_time_str,publish_time_str,is_collect,salary_month,property_name,is_applied,publish_end_time_str,avatars,regular_worker_str,work_time_name,original_salary,benefit_name,jd_qrcode,is_end_time"
    }
    res = await positionService.positionDetail(paramsInfo);
    if (res.code === 0) {
      res.data.joint = `${((res.data.address[0]||{}).city||{}).name} |  ${res.data.work_time_name} ${res.data.education_name && "|"} ${res.data.education_name}`
      setData(res.data);
      getList(res.data);
      return res
    }
  } const { company = {} } = info;
  function getList(info) {
    let expand = "education_name,update_time_str,is_applied,work_time_name,salary_month,original_salary,salary_type_name,work_time_name,company"
    positionService.positionList({ removed_id: info.id, function_type: info.function_type, "per-page": 8, expand }).then(res => {
      if (res.code === 0) {
        res.data.list.forEach(item => {
          item.joint = `${((item.address[0] || {}).city || {}).name} |  ${item.work_time_name} ${item.education_name && "|"} ${item.education_name}`
        })
        setList(res.data.list)
      }
    })
  }
  function onDetail(item) {
    // const w=window.open('about:blank');
    window.location.href = "/positionDetail/" + item.id;
  }
  return (
    <div className="position-detail-container">
      <Head info={info} history={props.history} />
      <div className="main">
        <div className="container-g">
          <div className="left-side">
            {
              info.desc &&
              <div>
                <div className="title">职位描述<div className="line" /></div>
                <div className="des" dangerouslySetInnerHTML={createMarkup(info.desc)} />
              </div>
            }
            {info.requirements &&
              <div>
                <div className="title">职位要求<div className="line" /></div>
                <div className="des" dangerouslySetInnerHTML={createMarkup(info.requirements)} />
              </div>
            }
            {
              info.benefit_name && info.benefit_name.length > 0 &&
              <div>
                <div className="title" style={{ marginBottom: "5px" }}>福利标签<div className="line" /></div>
                {info.benefit_name.map(item => {
                  return (<div className="label" title={item.name} key={item.name}>{item.name}</div>)
                })}
              </div>
            }
            <div className="title">工作地点<div className="line" /></div>
            <div className="des">
              {info.address && info.address.map(item => {
                return (
                  <p className="ellipsis" title={item.address} key={item.id}><CustomIcon type="icon-map" style={{ fontSize: "18px", marginRight: "4px" }} />{item.province.name === item.city.name ? item.province.name : `${item.province.name}${item.city.name}`}{item.region.name}{item.address}</p>
                )
              })}
            </div>
            {
              list && list.length > 0 &&
              <div>
                <div className="interest-title">你有可能感兴趣</div>
                <div className="interest">
                  {list.map(item => {
                    return (
                      <CompanyCard onClick={() => { onDetail(item) }} item={item} key={item.id} />)
                  })}
                </div>
              </div>
            }
          </div>
          <div className="right-side">
            <div className="bg"><img src={company.logo} /></div>
            <div className="base">
              <div className="name ellipsis" title={company.name}>{company.name}</div>
              {company.scale_name &&
                <div className="row"><CustomIcon type="icon-ic_guimo" style={{ fontSize: "15px", marginRight: "6px" }} /><span>{company.scale_name}</span></div>
              }
              {company.type_name &&
                <div className="row"><CustomIcon type="icon-ic_qiyexingzhi" style={{ fontSize: "15px", marginRight: "6px" }} /><span>{company.type_name}</span></div>
              }
              <div className="row"><CustomIcon type="icon-ic_hangye" style={{ fontSize: "15px", marginRight: "6px" }} />{company.industry_name && company.industry_name.map(item => {
                return (<span key={item.id}>{item.name}</span>)
              })}</div>
            </div>
          </div>
        </div>
        <DeliverDialog visible={open} onCancel={() => { setOpen(false) }} />
      </div>
    </div>
  )
}
function DeliverDialog(props) {
  return (
    <Modal
      width="480px"
      title={<b>投递信息确认</b>}
      visible={props.visible}
      onCancel={props.onCancel}
      footer={null}
    >
      <div className="deliver-dialog">
        <div style={{ color: "#666666", textAlign: "center", marginBottom: "20px" }}>简历完整度低于50%，请先完善简历</div>
        <div style={{ textAlign: "center" }}><Button type="primary" onClick={() => { }}>完善简历</Button></div>
      </div>
    </Modal>
  )
}
