import React from "react";
import { connect } from "react-redux";
import { Layout, Menu, Tabs, Table } from "antd";
import CustomIcon from "components/CustomIcon";
import SecondTabs from "components/SecondTabs/tabs";
import userModel from "store/reducers/userModel";
import PositionList from "./positionList";


const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;

@connect(
  ({ index, user }) => {
    return { ...index, user: user };
  },
  {
    ...userModel.actions,
  }
)
export class NoLoginPosition extends React.Component {

  state = {
  }

  render() {

    return (
      <div className="no-login-position" style={{ margin: "30px 0" }}>
        <PositionList history={this.props.history} />
      </div>
    );
  }
}
@connect(
  ({ index, user }) => {
    return { ...index, user: user };
  },
  {
    ...userModel.actions,
  }
)
export class LoginPosition extends React.Component {

  state = {
  }

  render() {

    return (
      <div className="login-position" >
        {/* <SecondTabs history={this.props.history} /> */}
        <div style={{ margin: "30px 0" }}>
          <PositionList history={this.props.history} />
        </div>
      </div>
    );
  }
}
